import type { ChainConfigMap } from "./types"

export const chainNames = [
  "osmosis",
  "sei",
  "injective",
  "neutron",
  "dymension",
] as const

export interface TvEndpoint {
  url: string
  type: "benchmarks"
  sleep_rate_limit_ms: number
}

export const tradingViewHistoryEndpoints: TvEndpoint[] = [
  {
    url: "https://benchmarks.pyth.network/v1/shims/tradingview/history",
    type: "benchmarks",
    sleep_rate_limit_ms: 3000,
  },
]

export const chainConfigMap: ChainConfigMap = {
  "osmosis-testnet": {
    // rpcUrl: "https://rpc.osmotest5.osmosis.zone",
    rpcUrl: "https://querier-testnet.levana.finance/rpc/osmosis-testnet",
    restUrl: "https://lcd.osmotest5.osmosis.zone",
    network: "testnet",
    lowGasPrice: "0.025",
    averageGasPrice: "0.03",
    highGasPrice: "0.035",
    chainId: "osmo-test-5",
    addressPrefix: "osmo",
    chainPrettyName: "Osmosis",
    asset: {
      base: "uosmo",
      display: "osmo",
      symbol: "OSMO",
    },
  },
  "osmosis-mainnet": {
    rpcUrl: "https://osmo-priv-rpc.kingnodes.com",
    restUrl: "https://osmo-priv-rest.kingnodes.com",
    rpcUrlFallbacks: [
      "https://osmosis-rpc.polkachu.com",
      "https://rpc.osmosis.zone",
      "https://rpc-osmosis.blockapsis.com",
      "https://osmosis-rpc.onivalidator.com",
      "https://rpc-osmosis.cosmos-spaces.cloud",
      "https://rpc-osmosis.ecostake.com",
    ],
    restUrlFallbacks: ["https://lcd.osmosis.zone"],
    network: "mainnet",
    lowGasPrice: "0.025",
    averageGasPrice: "0.03",
    highGasPrice: "0.035",
    chainId: "osmosis-1",
    addressPrefix: "osmo",
    chainPrettyName: "Osmosis",
    asset: {
      base: "uosmo",
      display: "osmo",
      symbol: "OSMO",
    },
  },
  "sei-testnet": {
    rpcUrl: "https://rpc.atlantic-2.seinetwork.io",
    restUrl: "https://rest.atlantic-2.seinetwork.io",
    network: "testnet",
    lowGasPrice: "0.1",
    averageGasPrice: "0.15",
    highGasPrice: "0.2",
    chainId: "atlantic-2",
    addressPrefix: "sei",
    chainPrettyName: "Sei",
    asset: {
      base: "usei",
      display: "sei",
      symbol: "SEI",
    },
  },
  "sei-mainnet": {
    rpcUrl: "https://rpc.sei-apis.com",
    restUrl: "https://rest.sei-apis.com",
    network: "mainnet",
    lowGasPrice: "0.1",
    averageGasPrice: "0.15",
    highGasPrice: "0.2",
    chainId: "pacific-1",
    addressPrefix: "sei",
    chainPrettyName: "Sei",
    asset: {
      base: "usei",
      display: "sei",
      symbol: "SEI",
    },
  },
  "injective-testnet": {
    rpcUrl: "https://injective-testnet-rpc.polkachu.com",
    restUrl: "https://k8s.testnet.lcd.injective.network",
    network: "testnet",
    lowGasPrice: "500000000",
    averageGasPrice: "1000000000",
    highGasPrice: "1500000000",
    chainId: "injective-888",
    addressPrefix: "inj",
    chainPrettyName: "Injective",
    asset: {
      base: "inj",
      display: "inj",
      symbol: "INJ",
    },
  },
  "injective-mainnet": {
    rpcUrl: "https://inj-priv-rpc.kingnodes.com",
    restUrl: "https://inj-priv-rest.kingnodes.com",
    rpcUrlFallbacks: [
      "https://injective-rpc.polkachu.com",
      "https://sentry.tm.injective.network",
    ],
    restUrlFallbacks: ["https://sentry.lcd.injective.network"],
    network: "mainnet",
    lowGasPrice: "500000000",
    averageGasPrice: "1000000000",
    highGasPrice: "1500000000",
    chainId: "injective-1",
    addressPrefix: "inj",
    chainPrettyName: "Injective",
    asset: {
      base: "inj",
      display: "inj",
      symbol: "INJ",
    },
  },
  "neutron-testnet": {
    rpcUrl: "https://rpc-falcron.pion-1.ntrn.tech",
    restUrl: "https://rest-falcron.pion-1.ntrn.tech",
    network: "testnet",
    lowGasPrice: "0.025",
    averageGasPrice: "0.03",
    highGasPrice: "0.035",
    chainId: "pion-1",
    addressPrefix: "neutron",
    chainPrettyName: "Neutron",
    asset: {
      base: "untrn",
      display: "ntrn",
      symbol: "NTRN",
    },
  },
  "neutron-mainnet": {
    // rpcUrl: "https://rpc.novel.remedy.tm.p2p.org",
    // restUrl: "https://api.novel.remedy.tm.p2p.org",
    rpcUrl: "https://rpc-arch.mainnet.neutron.tm.p2p.org",
    restUrl: "https://api-arch.mainnet.neutron.tm.p2p.org",
    rpcUrlFallbacks: [
      "https://neutron.rpc.p2p.world/jO9HBxSgslA6xsgQB2DAY771CR9dI14qPDRxrWqk",
    ],
    network: "mainnet",
    lowGasPrice: "0.025",
    averageGasPrice: "0.03",
    highGasPrice: "0.035",
    chainId: "neutron-1",
    addressPrefix: "neutron",
    chainPrettyName: "Neutron",
    asset: {
      base: "untrn",
      display: "ntrn",
      symbol: "NTRN",
    },
  },
  "dymension-testnet": {
    rpcUrl: "https://querier-testnet.levana.finance/rpc/dymension-testnet",
    // rpcUrl: "http://18.199.53.161:26657",
    // Fairly certain this is wrong...
    restUrl: "http://18.199.53.161:26657",
    network: "testnet",
    lowGasPrice: "0.025",
    averageGasPrice: "0.03",
    highGasPrice: "0.035",
    chainId: "rollappwasm_1234-2",
    addressPrefix: "rol",
    chainPrettyName: "Dymension Rollup Testnet",
    asset: {
      base: "urax",
      display: "rax",
      symbol: "RAX",
    },
  },
}
