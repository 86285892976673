// import { isDesktop } from "react-device-detect"
import { useStore } from "zustand"

import type { ChainNetworkId } from "@common/target/types"
import type { MarketConfig } from "@future/market/config/types"
import type { ContextStoreProp } from "@future/context/store"

import {
  type UseElementsValuesProps,
  useElementsValues,
} from "./elements/utils"
import type { ElementsChainName } from "./elements/config"
import type { ElementsContentProps } from "./elements/ElementsContent"

export type BridgeableChainNetworkId = Extract<
  ChainNetworkId,
  "osmosis-mainnet" | "injective-mainnet"
>

export interface BridgeSelectorProps extends ContextStoreProp<"standard"> {
  marketConfig?: MarketConfig
  children: (props?: BridgeSelection) => JSX.Element
}

export type BridgeSelection = BridgeInternalSelection | BridgeExternalSelection

export type BridgeInternalSelection = BridgeElementsSelection

export interface BridgeElementsSelection {
  type: "elements"
  values: ElementsContentProps["values"]
}

export interface BridgeExternalSelection {
  type: "external"
  url: string
}

const BridgeSelector = (props: BridgeSelectorProps) => {
  const { marketConfig } = props
  const chainNetworkId = useStore(
    props.contextStore,
    (state) => state.chain.config.chainNetworkId,
  )

  switch (chainNetworkId) {
    case "osmosis-mainnet":
      if (!marketConfig) {
        return (
          <ElementsBridge
            fromChainName="ethereum"
            fromToken="eth"
            toChainName="osmosis"
            toMarketConfig={undefined}
          >
            {props.children}
          </ElementsBridge>
        )
      }

      switch (marketConfig.id) {
        case "DOT_USD":
          return (
            <ExternalBridge url="https://app.trustless.zone/?from=POLKADOT&to=OSMOSIS">
              {props.children}
            </ExternalBridge>
          )
        case "SEI_USD":
          return (
            <ExternalBridge url="https://app.sei.io/bridge">
              {props.children}
            </ExternalBridge>
          )
        default:
          return (
            <ElementsBridge
              fromChainName="ethereum"
              fromToken="eth"
              toChainName="osmosis"
              toMarketConfig={marketConfig}
            >
              {props.children}
            </ElementsBridge>
          )
      }
    case "injective-mainnet":
      return (
        <ExternalBridge url="https://hub.injective.network/bridge/">
          {props.children}
        </ExternalBridge>
      )
    default:
      return props.children()
  }
}

type BridgeSelectorContentProps = Pick<BridgeSelectorProps, "children">

const ElementsBridge = <
  From extends ElementsChainName,
  To extends ElementsChainName,
>(
  props: BridgeSelectorContentProps & UseElementsValuesProps<From, To>,
) => {
  const values = useElementsValues(props)
  return props.children({ type: "elements", values })
}

const ExternalBridge = (
  props: BridgeSelectorContentProps & { url: string },
) => {
  return props.children({ type: "external", url: props.url })
}

export default BridgeSelector
