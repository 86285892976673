import Button from "@mui/joy/Button"
import FormControl from "@mui/joy/FormControl"
import FormLabel from "@mui/joy/FormLabel"
import Input from "@mui/joy/Input"
import Stack from "@mui/joy/Stack"
import { useTranslation } from "react-i18next"

import CopyIdButton from "@common/ui/CopyIdButton"
import { useChainStore } from "@future/libs/chain/store"
import { RoutePath } from "@perps/routes"
import { useTwitterShare } from "@future/utils/share"

const ReferralLinkSection = () => {
  const { t } = useTranslation("perpsCommon")

  const walletAddress = useChainStore(
    (state) => state.connectedWalletSession()?.walletAddress,
  )

  const referralLink = `trade.levana.finance/${RoutePath.referral}/${walletAddress}`

  const { openTwitter } = useTwitterShare()

  const handleTwitterShare = () => {
    openTwitter(t("referral.myLink.share.message"), referralLink)
  }

  return (
    <Stack spacing={1}>
      <FormControl>
        <FormLabel>{t("referral.myLink.title")}</FormLabel>
        <Input
          endDecorator={
            <CopyIdButton
              type="referral"
              id={`https://${referralLink}`}
              showId={false}
            />
          }
          value={referralLink}
          size="lg"
          slotProps={{
            input: {
              spellCheck: "false",
              sx: ({ vars }) => ({
                color: vars.palette.text.secondary,
              }),
            },
          }}
          aria-label="referral link"
        />
      </FormControl>
      <Button onClick={handleTwitterShare}>
        {t("referral.myLink.share.title")}
      </Button>
    </Stack>
  )
}

export default ReferralLinkSection
