import type { Metadata } from "@cosmos-kit/core"

import { useLazyLoad } from "@future/utils/lazyLoad"

import type { ChainConfig } from "./chain"

const importCosmosKitProvider = () =>
  import("@future/libs/chain/cosmosKit/CosmosKitProvider")

const importInjectiveProvider = () =>
  import("@future/libs/chain/injective/InjectiveProvider")

export interface ChainProviderProps {
  chainConfig: ChainConfig
  walletConnectMetadata?: Metadata
}

const ChainProvider = (props: ChainProviderProps) => {
  const Provider = useLazyLoad(
    (): ReturnType<
      typeof importCosmosKitProvider | typeof importInjectiveProvider
    > => {
      switch (props.chainConfig.chainName) {
        case "osmosis":
        case "sei":
        case "neutron":
        case "dymension":
          return importCosmosKitProvider()
        case "injective":
          return importInjectiveProvider()
      }
    },
  )

  if (!Provider) {
    return null
  }

  return <Provider {...props} />
}

export default ChainProvider
