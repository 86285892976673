import type { WalletRegistry } from "../registry"
import Icon from "./icon"

export type Brand = "viewing"

export const registry: WalletRegistry = {
  name: "View Only",
  icon: Icon,
  provider: () => undefined,
  install: {
    chrome: "",
  },
  errorOptions: () => undefined,
  accountChangeKey: undefined,
}
