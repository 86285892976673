import List from "@mui/joy/List"
import ListDivider from "@mui/joy/ListDivider"
import ListItem from "@mui/joy/ListItem"
import ListItemButton, {
  type ListItemButtonProps,
} from "@mui/joy/ListItemButton"
import ListItemContent from "@mui/joy/ListItemContent"
import ListItemDecorator from "@mui/joy/ListItemDecorator"
import Stack from "@mui/joy/Stack"
import type { SvgIconProps } from "@mui/joy/SvgIcon"
import Typography from "@mui/joy/Typography"

export interface HeaderMenuListProps {
  items: HeaderMenuListItem[]
  onClose: () => void
}

export interface HeaderMenuListItem {
  title: string
  subtitle?: string
  Icon: React.JSXElementConstructor<SvgIconProps>
  action?: () => void
  actionComponent?: HeaderMenuActionComponent
}

export type HeaderMenuActionProps = Omit<ListItemButtonProps, "ref">

export type HeaderMenuActionComponent = React.ForwardRefExoticComponent<
  HeaderMenuActionProps & React.RefAttributes<HTMLDivElement>
>

const HeaderMenuList = (props: HeaderMenuListProps) => {
  const items = props.items.map((item) => {
    const { action } = item
    const modifiedItem = { ...item }

    if (action) {
      modifiedItem.action = () => {
        action()
        props.onClose()
      }
    }

    return modifiedItem
  })

  return (
    <Stack component={List} divider={<ListDivider inset="gutter" />}>
      {items.map((item) => (
        <ListItem key={item.title}>
          {item.actionComponent ? (
            <ListItemButton
              slots={{ root: item.actionComponent }}
              onClick={props.onClose}
              aria-label={item.title.toLowerCase()}
            >
              <ItemContent item={item} />
            </ListItemButton>
          ) : item.action ? (
            <ListItemButton
              onClick={item.action}
              aria-label={item.title.toLowerCase()}
            >
              <ItemContent item={item} />
            </ListItemButton>
          ) : (
            <ItemContent item={item} />
          )}
        </ListItem>
      ))}
    </Stack>
  )
}

const ItemContent = (props: { item: HeaderMenuListItem }) => (
  <>
    <ListItemDecorator>
      <props.item.Icon fontSize="xl2" />
    </ListItemDecorator>
    <ListItemContent>
      <Typography level="body2">{props.item.title}</Typography>
      {props.item.subtitle && (
        <Typography level="subtitle2">{props.item.subtitle}</Typography>
      )}
    </ListItemContent>
  </>
)

export default HeaderMenuList
