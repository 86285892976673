import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { isDesktop } from "react-device-detect"

import { WalletType } from "@future/libs/chain/wallet"
import { Report } from "@future/libs/error/report"
import { notification } from "@future/notification"
import {
  WalletActivityStatus,
  type WalletConnector,
  type WalletExtensionConnector,
  type WalletMobileConnector,
} from "@future/libs/chain/wallet"
import { AppError } from "@future/libs/error/AppError"
import { useChainStore } from "@future/libs/chain/store"
import type { NavigationModalControlProps } from "@future/libs/theme/NavigationModal"

import type { WalletConnectRootProps } from "."
import {
  WalletConnectDevice,
  filterConnectors,
  useDevice,
} from "../WalletConnectList"
import WalletConnectInput from "../WalletConnectInput"
import WalletConnectQr from "../WalletConnectQr"
import WalletConnectMobileSupport from "../WalletConnectMobileSupport"

export const useController = (props: WalletConnectRootProps) => {
  const [retryConnectorId, setRetryConnectorId] = useState<string>()
  const [waitingMobileConnectorId, setWaitingMobileConnectorId] =
    useState<string>()
  const { t } = useTranslation("common")

  const walletConnectors = useChainStore((state) => state.getWalletConnectors())
  const { device, handleDeviceChange } = useDevice(walletConnectors)
  const filteredConnectors = walletConnectors.filter(filterConnectors(device))

  const handleClick = async (connector: WalletConnector) => {
    if (connector.type === WalletType.input) {
      props.push({
        id: WalletConnectInput.name,
        title: t("wallet.connect.input.title"),
        canPop: true,
        canClose: true,
        view: (viewProps) => (
          <WalletConnectInput
            {...viewProps}
            contextStore={props.contextStore}
            connector={connector}
          />
        ),
      })
    } else if (connector.installed() === false) {
      return handleInstall(connector)
    } else {
      return handleConnect(connector)
    }
  }

  const handleInstall = (
    connector: WalletExtensionConnector | WalletMobileConnector,
  ) => {
    if (isDesktop) {
      setRetryConnectorId(connector.brand)
      window.open(connector.installUrl(), "_blank")
    } else {
      props.push({
        id: WalletConnectMobileSupport.name,
        title: t("wallet.install.title"),
        canPop: true,
        canClose: true,
        view: (viewProps) => (
          <WalletConnectMobileSupport {...viewProps} connector={connector} />
        ),
      })
    }
  }

  const handleConnect = async (
    connector: WalletExtensionConnector | WalletMobileConnector,
  ) => {
    if (device === WalletConnectDevice.mobile) {
      const interval = 100
      let attempts = 100

      const _id = setInterval(() => {
        const qr = connector.qr()

        if (qr) {
          switch (qr.status) {
            case WalletActivityStatus.init:
              setWaitingMobileConnectorId(connector.brand)
              break

            case WalletActivityStatus.done:
              if (qr.code) {
                const { code } = qr
                props.push({
                  id: WalletConnectQr.name,
                  title: t("wallet.dialog.title"),
                  canPop: true,
                  view: (viewProps) => (
                    <WalletConnectQr
                      {...viewProps}
                      qrCode={code}
                      icon={connector.icon}
                    />
                  ),
                })
              } else {
                Report.message("QR done without data")
              }
              clearInterval(_id)
              break

            case WalletActivityStatus.error: {
              const error = AppError.fromKey("wallet.unknown", {
                cause: qr.error,
                level: "suppress",
              })
              notification.error(error)
              clearInterval(_id)
              break
            }
          }
        }

        // if (connector.status() === WalletConnectorStatus.connected) {
        //   clearInterval(_id)
        //   return
        // }

        if (attempts <= 0) {
          clearInterval(_id)
          Report.message("No QR after time limit")
        }

        attempts -= 1
      }, interval)
    } else {
      setWaitingMobileConnectorId(undefined)
    }

    try {
      await connector.connect()
    } catch (error) {
      Report.error(AppError.fromError(error, { text: "Failed to connect" }))
    }
  }

  useDismissOnConnected(props)

  return {
    waitingMobileConnectorId,
    connectors: filteredConnectors,
    retryConnectorId,
    handleClick,
    device,
    handleDeviceChange,
  }
}

const useDismissOnConnected = (modal: NavigationModalControlProps) => {
  const connectedWalletSession = useChainStore((state) =>
    state.connectedWalletSession(),
  )

  useEffect(() => {
    if (connectedWalletSession) {
      modal.close()
    }
  }, [connectedWalletSession, modal.close])
}
