import type {
  LibrarySymbolInfo,
  ResolutionString,
} from "@tradingview/charting_library"

export const supportedResolutions = [
  "1",
  "5",
  "15",
  "30",
  "60",
  "240",
  "1D",
  // "7D" as ResolutionString,
] as const

export type SupportedResolutions = (typeof supportedResolutions)[number]

type SymbolInfo = Pick<
  LibrarySymbolInfo,
  "ticker" | "name" | "full_name" | "description" | "pricescale"
>

export const createSymbolInfo = (symbolInfo: SymbolInfo): LibrarySymbolInfo => {
  return {
    type: "crypto",
    session: "24x7",
    exchange: "",
    listed_exchange: "",
    timezone: "Etc/UTC",
    format: "price",
    minmov: 1,
    supported_resolutions:
      supportedResolutions as unknown as ResolutionString[],
    has_intraday: true, // needed for `supportedResolutions` under 1D
    has_ticks: true, // ?
    has_weekly_and_monthly: true, // ?
    ...symbolInfo,
  }
}
