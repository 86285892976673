import { setUser as SentrySetUser } from "@sentry/react"

export const truncateWalletAddress = (
  blockchain: string,
  prefix: number,
  suffix: number,
  walletAddress: string,
) => {
  if (blockchain.length + prefix + suffix >= walletAddress.length) {
    return walletAddress
  }

  const start = walletAddress.slice(0, blockchain.length + prefix)
  const end = walletAddress.slice(-suffix, walletAddress.length)

  return `${start}...${end}`
}

export const identifyUser = (walletAddress: string | undefined | null) => {
  if (walletAddress) {
    SentrySetUser({ id: walletAddress })
    gtag("event", "login", { address: walletAddress })
  } else {
    SentrySetUser(null)
  }
}
