import { isDesktop } from "react-device-detect"
import { UAParser } from "ua-parser-js"
import type { SvgIconProps } from "@mui/joy/SvgIcon"
import { fromBech32 } from "@cosmjs/encoding"

import type { SigningClient } from "@perps/sdk/client/CosmosClient"
import type { ChainNetworkId } from "@common/target/types"
import { chainConfigMap } from "@common/target/constants"
import type { MapValue } from "@future/utils/typeScript"
import type { AppError, AppErrorKeyOptions } from "@future/libs/error/AppError"

import { type WalletBrand, walletRegistryMap } from "./wallets/registry"
import type * as Viewing from "./wallets/viewing"

type WalletId = string

export enum WalletType {
  extension,
  mobile,
  input,
}

export interface ChainWalletConfig<
  Wallet,
  Type extends WalletType = WalletType,
> {
  brand: WalletBrand
  type: Type
  wallet: Wallet
  /**
   * The message provided by the wallet when it rejects at any connecting stage.
   * This message can be different for the same wallet across different chains.
   * Below are different stages to reveal a reject message.
   *
   * Unlock reject message:
   * 1. Lock the wallet
   * 2. Connect to the app
   * 3. Reject the wallet
   *
   * Access reject message:
   * 1. Create a new account in the wallet
   * 2. Connect to the app for the first time
   * 3. Reject the wallet
   *
   * Previous session without extension message:
   * 1. Connect to the app
   * 2. Remove the wallet extension
   * 3. Refresh the app to auto-connect
   * Note: Included for completeness, but this case should be already handled in
   * the auto-connect logic.
   */
  connectRejectMessages: (string | RegExp)[]
  exclude?: ChainWalletExclude[]
}

export interface ViewingWalletConfig<
  Type extends WalletType.input = WalletType.input,
> {
  brand: Viewing.Brand
  type: Type
}

export interface ChainWalletExclude {
  chainId: string
  reason: "unsupported-temporarily" | "unsupported-permanently"
}

export type WalletConnectorsMap = Record<WalletId, WalletConnector>

export type WalletConnector =
  | WalletExtensionConnector
  | WalletMobileConnector
  | WalletInputConnector

interface WalletBaseConnector {
  brand: WalletBrand
  name: string
  icon: string | React.JSXElementConstructor<SvgIconProps>
  type: WalletType
  disabledReason?: ChainWalletExclude["reason"]
}

export interface WalletExtensionConnector extends WalletBaseConnector {
  type: WalletType.extension
  connect: () => Promise<void>
  installUrl: () => string
  installed: () => boolean | undefined
  qr: () =>
    | {
        status: WalletActivityStatus
        code?: string
        error?: string
      }
    | undefined
}

export interface WalletMobileConnector extends WalletBaseConnector {
  type: WalletType.mobile
  connect: () => Promise<void>
  installUrl: () => string
  installed: () => boolean | undefined
  qr: () =>
    | {
        status: WalletActivityStatus
        code?: string
        error?: string
      }
    | undefined
}

export interface WalletInputConnector extends WalletBaseConnector {
  type: WalletType.input
  connect: (address: string) => Promise<void>
}

export interface ProxyWalletData {
  address: string
  truncatedAddress: string
  name: string
}

export interface ConnectedWalletSession extends WalletSessionBrand {
  walletAddress: string
  proxyWallet?: ProxyWalletData
  truncatedWalletAddress: string
  pubKey: () => Promise<string>
  explorerUrl: (hash: string) => string | undefined
  disconnect: () => Promise<void>
  chainSigningClient: SigningClient
}

export interface ConnectedWalletState {
  chain: "cosmos" | "injective"
  brand: WalletBrand
  address: string
}

interface WalletSessionBrand {
  walletBrand: WalletBrand
}

export type WalletSession =
  | {
      status: WalletConnectorStatus.disconnected
    }
  | ({
      status: WalletConnectorStatus.connecting
    } & WalletSessionBrand)
  | ({
      status: WalletConnectorStatus.connected
    } & ConnectedWalletSession)
  | ({
      status: WalletConnectorStatus.error | WalletConnectorStatus.rejected
      error: AppError
    } & WalletSessionBrand)

export enum WalletConnectorStatus {
  disconnected = "disconnected",
  connecting = "connecting",
  connected = "connected",
  rejected = "rejected",
  error = "error",
}

export enum WalletActivityStatus {
  init = "init",
  pending = "pending",
  done = "done",
  error = "error",
}

export const isKeplrBrowser =
  "keplr" in window &&
  typeof window.keplr === "object" &&
  window.keplr !== null &&
  "mode" in window.keplr &&
  window.keplr.mode === "mobile-web"

export const isLeapBrowser = window.navigator.userAgent.includes("LeapCosmos")

export const isOkxBrowser = window.navigator.userAgent.includes("OKApp")

export const createChainWalletConfig = <
  Wallet,
  WalletConfig extends ChainWalletConfig<Wallet>,
>(
  config: WalletConfig,
): [WalletBrand, WalletConfig] => {
  return [config.brand, config]
}

export const supportsExtension = (connectors: WalletConnector[]) => {
  for (const connector of connectors) {
    if (connector.type === WalletType.extension && connector.installed()) {
      return true
    }
  }

  return isDesktop
}

export const excludeAllPermanentlyExcept = (includeIds: ChainNetworkId[]) => {
  const chainNetworkIds = Object.keys(chainConfigMap) as ChainNetworkId[]

  return chainNetworkIds.reduce<ChainWalletExclude[]>(
    (exclude, chainNetworkId) => {
      if (!includeIds.includes(chainNetworkId)) {
        exclude.push({
          chainId: chainConfigMap[chainNetworkId].chainId,
          reason: "unsupported-permanently",
        })
      }

      return exclude
    },
    [],
  )
}

type CommonWalletConnectorReturn<Type extends WalletType> = Pick<
  WalletConnector,
  "name" | "icon" | "brand"
> & {
  type: Type
}

export const commonWalletConnector = <Type extends WalletType, Wallet>(
  chainWalletConfig: Type extends WalletType.input
    ? ViewingWalletConfig<Type>
    : ChainWalletConfig<Wallet, Type>,
): CommonWalletConnectorReturn<Type> => {
  const walletRegistry = walletRegistryMap[chainWalletConfig.brand]

  return {
    name: walletRegistry.name,
    icon: walletRegistry.icon,
    brand: chainWalletConfig.brand,
    type: chainWalletConfig.type as Type,
  }
}

export const commonWalletExtensionOrMobileConnector = <Wallet>(
  chainWalletConfig: ChainWalletConfig<
    Wallet,
    WalletType.extension | WalletType.mobile
  >,
  chainId: string,
): Pick<
  WalletExtensionConnector | WalletMobileConnector,
  | "name"
  | "icon"
  | "brand"
  | "type"
  | "disabledReason"
  | "installUrl"
  | "installed"
> => {
  const walletRegistry = walletRegistryMap[chainWalletConfig.brand]
  const common = commonWalletConnector(chainWalletConfig)

  const disabledReason = chainWalletConfig.exclude?.find(
    (exclude) => exclude.chainId === chainId,
  )?.reason

  return {
    ...common,
    disabledReason,
    installUrl: () => {
      const userAgent = new UAParser()
      const browser = userAgent.getBrowser()
      const os = userAgent.getOS()

      const url = (() => {
        switch (os.name) {
          case "Android":
            return walletRegistry.install.android
          case "iOS":
            return walletRegistry.install.ios
        }

        switch (browser.name) {
          case "Edge":
            return walletRegistry.install.edge
          case "Firefox":
            return walletRegistry.install.firefox
          default:
            return walletRegistry.install.chrome
        }
      })()

      return url ?? walletRegistry.install.chrome
    },
    installed: () => walletRegistry.provider() !== undefined,
  }
}

export const searchErrorMaps = (
  walletConfigs: ChainWalletConfig<unknown>[],
  message: string,
): AppErrorKeyOptions | undefined => {
  for (const walletConfig of walletConfigs) {
    const registry = walletRegistryMap[walletConfig.brand]
    const options = registry.errorOptions(message)

    if (options !== undefined) {
      return options
    }
  }
}

export const viewingWalletConfigWith = <
  M extends Map<WalletBrand, Value>,
  Value = MapValue<M>,
>(
  walletConfigMap: M,
) => {
  return new Map<WalletBrand, Value | ViewingWalletConfig>([
    ...walletConfigMap,
    [
      "viewing",
      {
        brand: "viewing",
        type: WalletType.input,
      },
    ],
  ])
}

export const isCosmosAddress = (address: string, prefix: string) => {
  try {
    const decodedAddress = fromBech32(address)
    return (
      decodedAddress.prefix === prefix &&
      (decodedAddress.data.length === 20 || decodedAddress.data.length === 32)
    )
  } catch {
    return false
  }
}
