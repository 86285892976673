import { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useShallow } from "zustand/react/shallow"

import { WalletConnectorStatus } from "@future/libs/chain/wallet"
import { extractFailureReason } from "@future/libs/error/utils"
import { Report } from "@future/libs/error/report"
import { identifyUser } from "@common/wallet/utils"
import { Event } from "@perps/analytics/events"
import { track } from "@perps/analytics/track"
import { notification } from "@future/notification"
import { AppError } from "@future/libs/error/AppError"
import { walletRegistryMap } from "@future/libs/chain/wallets/registry"
import { useChainStore } from "@future/libs/chain/store"

const ChainReporter = () => {
  const walletSession = useChainStore(
    useShallow((state) => state.walletSession),
  )
  const previousWalletSessionStatusRef = useRef(walletSession.status)
  const { t } = useTranslation("error")

  useEffect(() => {
    switch (walletSession.status) {
      case WalletConnectorStatus.connected: {
        const address = walletSession.walletAddress

        identifyUser(address)

        Report.setTag("connector.id", walletSession.walletBrand)

        Report.addBreadcrumb({
          level: "info",
          type: "user",
          category: "wallet",
          message: "Wallet connected",
          data: { address },
        })

        track(
          Event.connectWallet.succeeded({
            wallet: walletSession.walletBrand,
          }),
        )
        break
      }

      case WalletConnectorStatus.disconnected:
        identifyUser(null)

        if (previousWalletSessionStatusRef.current !== walletSession.status) {
          Report.addBreadcrumb({
            level: "info",
            type: "user",
            category: "wallet",
            message: "Wallet disconnected",
          })
        }
        break

      case WalletConnectorStatus.rejected:
        Report.addBreadcrumb({
          level: "info",
          type: "user",
          category: "wallet",
          message: "Wallet rejected from connecting",
        })
        break

      case WalletConnectorStatus.error: {
        const errorOptions = (() => {
          if (walletSession.error.rootError.cause instanceof Error) {
            const walletRegistry = walletRegistryMap[walletSession.walletBrand]
            return walletRegistry.errorOptions(
              walletSession.error.rootError.cause.message,
            )
          }
        })()

        const error = (() => {
          if (errorOptions) {
            const { key, ...options } = errorOptions

            return AppError.fromKey(key, {
              ...options,
              cause: walletSession.error,
            })
          } else {
            return AppError.fromError(walletSession.error, {
              text: "Wallet error",
            })
          }
        })()

        if (error.level === "warning") {
          notification.warning(error.toString(t))
        } else {
          notification.error(error)
        }

        if (!error.disablePresentation) {
          track(Event.connectWallet.failed({}, extractFailureReason(error)))
        }

        break
      }
    }

    previousWalletSessionStatusRef.current = walletSession.status
  }, [walletSession, t])

  return null
}

export default ChainReporter
