import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import Button from "@mui/joy/Button"
import Stack from "@mui/joy/Stack"
import Typography from "@mui/joy/Typography"
import Skeleton from "@mui/joy/Skeleton"
import { useQuery } from "@tanstack/react-query"

import { useBannerContext } from "@perps/banner/BannerContext"
import LvnIcon from "@common/icons/LvnIcon"
import { setLocalStorageItem } from "@common/storage/localStorage"
import GetLvnModal from "@future/lvn/GetLvnModal"
import { useModal } from "@future/libs/modal"
import PriceChange from "@common/ui/PriceChange"
import { lvnPriceQueryOptions } from "@future/network/lvnPrice"
import type { ContextStoreProp } from "@future/context/store"

const LVN_BANNER_ID = "get-lvn"

export type LvnBannerProps = ContextStoreProp<"standard">

const LvnBanner = (props: LvnBannerProps) => {
  const bannerContext = useBannerContext()
  const { show, hide } = bannerContext.common

  useEffect(() => {
    show(
      LVN_BANNER_ID,
      <LvnBannerContent
        {...props}
        onDismiss={() => {
          hide(LVN_BANNER_ID)
          setLocalStorageItem(LVN_BANNER_ID, true)
        }}
      />,
    )
    return () => hide(LVN_BANNER_ID)
  }, [show, hide, props])

  return null
}

interface LvnBannerContentProps extends LvnBannerProps {
  onDismiss: () => void
}

const LvnBannerContent = (props: LvnBannerContentProps) => {
  const { present } = useModal()
  const { t } = useTranslation("perpsCommon")

  const lvnPriceResult = useQuery(lvnPriceQueryOptions())

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems={{ sm: "center" }}
      justifyContent="center"
      columnGap={8}
      rowGap={1}
      sx={{ p: 2, backgroundColor: (theme) => theme.palette.background.level1 }}
    >
      <Stack direction="row" alignItems="center" columnGap={1}>
        <LvnIcon size="lg" />
        <Typography
          level="label1"
          textColor="text.primary"
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: 0.75,
          }}
        >
          <Skeleton
            loading={!lvnPriceResult.data}
            variant="rectangular"
            width={200}
            height={25}
          >
            {lvnPriceResult.data && (
              <>
                {`LVN ${lvnPriceResult.data.price.toSymbolAsset({
                  maxDecimalPlaces: 4,
                })} `}
                <PriceChange
                  percentage={lvnPriceResult.data.percent24hChange}
                  timePeriod="1d"
                />
              </>
            )}
          </Skeleton>
        </Typography>
      </Stack>
      <Typography level="body2" textColor="text.secondary">
        {t("lvn.banner.description")}
      </Typography>
      <Stack direction="row" alignItems="center" columnGap={1}>
        <Button
          variant="solid"
          color="primary"
          size="sm"
          sx={{ width: { xs: "50%", sm: "unset" } }}
          onClick={() =>
            present(<GetLvnModal contextStore={props.contextStore} />)
          }
          aria-label="learn more"
        >
          <Typography level="button1">{t("lvn.banner.learn_more")}</Typography>
        </Button>
        <Button
          variant="outlined"
          color="neutral"
          size="sm"
          sx={{ width: { xs: "50%", sm: "unset" } }}
          onClick={props.onDismiss}
          aria-label="close banner"
        >
          <Typography level="button1">{t("lvn.banner.close")}</Typography>
        </Button>
      </Stack>
    </Stack>
  )
}

export default LvnBanner
