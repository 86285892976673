import Avatar from "@mui/joy/Avatar"
import type { SvgIconProps } from "@mui/joy/SvgIcon"

import WalletIcon from "@common/icons/WalletIcon"

export interface WalletConnectAvatarProps {
  icon: string | React.JSXElementConstructor<SvgIconProps>
}

const WalletConnectAvatar = (props: WalletConnectAvatarProps) => {
  const src = typeof props.icon === "string" ? props.icon : undefined

  return (
    <Avatar
      variant="plain"
      src={src}
      sx={({ spacing }) => ({ "--Avatar-radius": spacing(1) })}
    >
      {props.icon !== "string" ? (
        <props.icon
          fontSize="xl2"
          sx={({ palette }) => ({ color: palette.text.primary })}
        />
      ) : (
        <WalletIcon
          fontSize="xl2"
          sx={({ palette }) => ({ color: palette.text.secondary })}
        />
      )}
    </Avatar>
  )
}

export default WalletConnectAvatar
