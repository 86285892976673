import {
  NotifiContext,
  type OsmosisParams,
  type InjectiveParams,
} from "@notifi-network/notifi-react-card"
import type { OfflineAminoSigner } from "@cosmjs/amino"
import { useState, useEffect } from "react"

import type { ConnectedWalletSession } from "@future/libs/chain/wallet"
import { Report } from "@future/libs/error/report"

import NotifiButton from "./NotifiButton"

export interface NotifiContextWrapperProps {
  walletSession: ConnectedWalletSession
}

const NotifiContextWrapper = ({ walletSession }: NotifiContextWrapperProps) => {
  const [walletPublicKey, setWalletPublicKey] = useState("")

  const signingClientKind = walletSession.chainSigningClient.kind
  const isSessionValid =
    walletSession.walletBrand !== "leapMetaMask" &&
    signingClientKind !== "viewing"

  useEffect(() => {
    if (isSessionValid) {
      walletSession.pubKey().then(setWalletPublicKey)
    }
  }, [walletSession, isSessionValid])

  if (!isSessionValid) {
    // account and signer is required
    return null
  }

  const walletBlockchain = ((): (
    | OsmosisParams
    | InjectiveParams
  )["walletBlockchain"] => {
    switch (signingClientKind) {
      case "cosmjs":
        return "OSMOSIS"
      case "injective":
        return "INJECTIVE"
    }
  })()

  const signMessage = async (message: Uint8Array): Promise<Uint8Array> => {
    switch (walletSession.chainSigningClient.kind) {
      case "cosmjs": {
        const signingClient = walletSession.chainSigningClient.value

        if ("signer" in signingClient) {
          const hackSigner = signingClient[`${"signer"}`] as OfflineAminoSigner
          const signer = walletSession.walletAddress

          if ("leap" in hackSigner) {
            const leap = hackSigner[`${"leap"}`] as {
              signArbitrary: (
                chainId: string,
                signer: string,
                data: unknown,
              ) => Promise<{
                signature: string
              }>
            }

            if ("signArbitrary" in leap) {
              const chainId =
                await walletSession.chainSigningClient.value.getChainId()
              const result = await leap.signArbitrary(chainId, signer, message)
              return Buffer.from(result.signature, "base64")
            } else {
              Report.message("Unable to connect Leap to notifi", "error")
            }
          } else if ("signAmino" in hackSigner) {
            const signdoc = {
              chain_id: "",
              account_number: "0",
              sequence: "0",
              fee: {
                gas: "0",
                amount: [],
              },
              msgs: [
                {
                  type: "sign/MsgSignData",
                  value: {
                    signer,
                    data: Buffer.from(message).toString("base64"),
                  },
                },
              ],
              memo: "",
            }
            const result = await hackSigner.signAmino(signer, signdoc)
            return Buffer.from(result.signature.signature, "base64")
          }
        }

        throw new Error("Failed to sign")
      }
      case "injective": {
        const signingClient = walletSession.chainSigningClient.value
        const result = await signingClient.options.walletStrategy.signArbitrary(
          walletSession.walletAddress,
          new TextDecoder().decode(message),
        )

        if (typeof result === "string") {
          return Buffer.from(result, "base64")
        }

        throw new Error("Failed to sign")
      }
      case "viewing":
        throw new Error("Cannot sign with this wallet")
    }
  }

  return (
    <NotifiContext
      dappAddress="levanafinance"
      env={"Production"}
      signMessage={signMessage}
      walletPublicKey={walletPublicKey}
      walletBlockchain={walletBlockchain}
      accountAddress={walletSession.walletAddress}
    >
      <NotifiButton walletSession={walletSession} />
    </NotifiContext>
  )
}

export default NotifiContextWrapper
