import { useCallback } from "react"
import { useTranslation } from "react-i18next"

import { notification } from "@future/notification"
import type { ConnectedWalletSession } from "@future/libs/chain/wallet"
import { AppError } from "@future/libs/error/AppError"
import { type Breadcrumb, Report } from "@future/libs/error/report"
import type { MarketConfig } from "@future/market/config/types"
import { EventKey } from "@perps/analytics/constants"
import type { ContextStoreProp } from "@future/context/store"

interface CallbackProps extends ContextStoreProp<"standard"> {
  connectedWalletSession: ConnectedWalletSession
  marketConfig: MarketConfig
}

export const useCrankCallback = () => {
  const { t } = useTranslation("common")

  return useCallback(
    async (props: CallbackProps) => {
      const { contextStore, connectedWalletSession, marketConfig } = props

      try {
        const response = await contextStore.getState().chain.client.execCrank({
          walletSession: connectedWalletSession,
          marketConfig,
        })

        if (response) {
          const breadcrumb: Breadcrumb = {
            level: "info",
            type: "user",
            category: "crank",
            message: "crank completed",
            data: { hash: response.transactionHash },
          }

          notification.transactionSuccess({
            href: connectedWalletSession.explorerUrl(response.transactionHash),
          })

          Report.addBreadcrumb(breadcrumb)
        }
      } catch (error: unknown) {
        const isOutOfGas = (() => {
          // This should _always_ be an AppError, but just in case, we'll defensively handle other kinds of errors too
          if (error instanceof AppError) {
            const context = error.presentationError.context
            if (context.messageVariant === "key") {
              switch (context.message) {
                case "chain.insufficientGas":
                case "chain.incorrectGasCalculated":
                case "chain.incorrectGasQuery":
                case "chain.incorrectGasSet":
                  return true
              }
            }
          }

          return false
        })()

        if (isOutOfGas) {
          notification.warning(t("crank.outOfGas"))
        } else {
          const appError = AppError.fromError(error, {
            text: "Failed to crank",
            analytics: {
              [EventKey.MARKET]: marketConfig.id,
            },
          })

          if (error instanceof Error) {
            notification.error(appError)
          } else {
            Report.error(appError)
          }
        }
      }
    },
    [t],
  )
}
