import Button from "@mui/joy/Button"
import Snackbar from "@mui/joy/Snackbar"
import IconButton from "@mui/joy/IconButton"
import Link from "@mui/joy/Link"
import Typography from "@mui/joy/Typography"
import { toast as controller } from "react-hot-toast"
import { useTranslation } from "react-i18next"

import type { AppError } from "@future/libs/error/AppError"
import XIcon from "@common/icons/XIcon"
import FaceUncertainIcon from "@common/icons/FaceUncertainIcon"
import { useModal } from "@future/libs/modal"

import NotificationSymbol from "./NotificationSymbol"
import NotificationErrorModal from "./NotificationErrorModal"
import { type NotificationProps, baseNotificationProps } from "./utils"

interface ErrorNotificationProps extends NotificationProps {
  error: AppError
}

const ErrorNotification = (props: ErrorNotificationProps) => {
  const { t } = useTranslation("common")
  const { present } = useModal()

  const presentErrorModal = () => {
    present(
      <NotificationErrorModal message={props.message} error={props.error} />,
      { key: ErrorNotification.name },
    )
    controller.dismiss(props.toast.id)
  }

  return (
    <Snackbar
      {...baseNotificationProps(props.toast)}
      startDecorator={
        <NotificationSymbol color="danger">
          <FaceUncertainIcon htmlColor="white" fontSize="xs" />
        </NotificationSymbol>
      }
      endDecorator={
        props.error.extra.link ? (
          <Button
            component={Link}
            href={props.error.extra.link.href}
            target="_blank"
            underline="none"
            variant="plain"
            color="neutral"
            sx={{ whiteSpace: "pre-line", lineHeight: 1.2 }}
            aria-label="error notification extra link"
          >
            {props.error.extra.link.text}
          </Button>
        ) : (
          <Button
            onClick={presentErrorModal}
            variant="plain"
            color="neutral"
            sx={{ whiteSpace: "pre-line", lineHeight: 1.2 }}
            aria-label="error notification more"
          >
            {t("notification.error.report")}
          </Button>
        )
      }
    >
      <Typography level="label1" textColor="text.primary">
        {props.message}
      </Typography>
      <IconButton
        color="neutral"
        onClick={() => controller.dismiss(props.toast.id)}
        sx={(theme) => ({
          "--IconButton-size": "22px",
          position: "absolute",
          top: {
            xs: "calc(-1 * var(--IconButton-size) - 8px)",
            sm: "calc(-1 * var(--IconButton-size))",
          },
          right: { xs: 0, sm: "calc(-1 * var(--IconButton-size))" },
          alignSelf: "start",
          borderRadius: theme.radius.md,
        })}
      >
        <XIcon fontSize="xs" />
      </IconButton>
    </Snackbar>
  )
}

export default ErrorNotification
