import * as Sentry from "@sentry/react"
import {
  type PropsWithChildren,
  useEffect,
  Suspense,
  lazy,
  type LazyExoticComponent,
} from "react"
import {
  type RouteObject,
  RouterProvider,
  createBrowserRouter,
  json,
  redirect,
  useNavigate as useRouterNavigate,
} from "react-router-dom"
import { useStore } from "zustand"

import { RoutePath } from "@perps/routes"
import { MarketSlug } from "@future/market/config/constants"
import type { TargetSlug } from "@future/target/info"
import { domainConfig } from "@future/target/domain"
import RootLayout from "@perps/root/RootLayout"
import { isProduction } from "@common/env/constants"
import { ModalProvider } from "@future/libs/modal"
import { AutoShowNetworkModal } from "@future/header/navList"
import LoadingContent from "@future/root/LoadingContent"
import ChainContainer from "@future/chain/ChainContainer"
import SetupContext from "@future/context/SetupContext"
import {
  isStandardContextStore,
  type ContextStoreProp,
} from "@future/context/store"
import InvalidRootLayout from "@future/root/InvalidRootContent"
import LoadingPage from "@future/root/LoadingPage"
import { AppError } from "@future/libs/error/AppError"
import ErrorPage from "@app/not-found"
import { chainConfigMap } from "@common/target/constants"
import type { ChainNetworkId } from "@common/target/types"
import { useReferralStore } from "@future/referral/referralCode"
import { chainNetworkIdToTargetId } from "@future/chain/utils"
import { isCosmosAddress } from "@future/libs/chain/wallet"

import RouteRootError, {
  RouteNotFoundError,
  TestErrorPage,
} from "./RouteRootError"
import { getDefaultMarketSlug, getDefaultTargetInfo } from "./utils"

const Pages: Record<
  PerpsRoute["route"],
  LazyExoticComponent<(props: ContextStoreProp<"standard">) => JSX.Element>
> = {
  earnList: lazy(() => import("@app/earn/page")),
  earnMarket: lazy(() => import("@app/earn/marketPage")),
  history: lazy(() => import("@app/history/page")),
  leaderboard: lazy(() => import("@app/leaderboard/marketPage")),
  markets: lazy(() => import("@app/markets/page")),
  stats: lazy(() => import("@app/stats/page")),
  trade: lazy(() => import("@app/trade/marketPage")),
}

const ShowPage = (props: ContextStoreProp<"initial">): JSX.Element => {
  const routeState = useStore(props.contextStore, (state) => state.routeState)

  switch (routeState.rootId) {
    case "unknown":
      switch (routeState.case) {
        case "initializing":
          return (
            <LoadingPage
              type="full-screen"
              desc={["Initializing"]}
              contextStore={props.contextStore}
            />
          )
        case "geoblocked":
          return (
            <InvalidRootLayout
              contextStore={props.contextStore}
              content={{ type: "geoblocked" }}
            />
          )
        case "maintenance":
          return (
            <InvalidRootLayout
              contextStore={props.contextStore}
              content={{
                type: "maintenance",
                message: routeState.message,
              }}
            />
          )
        case "unknown":
          return <ErrorPage contextStore={props.contextStore} />
      }
      break
    case "trade":
    case "earnMarket":
    case "leaderboard":
      switch (routeState.case) {
        case "found":
          return (
            <PageContainer
              contextStore={props.contextStore}
              rootId={routeState.rootId}
            />
          )
        case "missing":
          return <PerformRedirect dest={renderRoute(routeState.newRoute)} />
      }
      break
    case "markets":
    case "earnList":
    case "history":
    case "stats":
      return (
        <PageContainer
          contextStore={props.contextStore}
          rootId={routeState.rootId}
        />
      )
  }
}

const PageContainer = (
  props: ContextStoreProp<"initial"> & { rootId: PerpsRoute["route"] },
) => {
  if (!isStandardContextStore(props.contextStore)) {
    return (
      <InvalidRootLayout
        contextStore={props.contextStore}
        content={{
          type: "root-error",
          error: AppError.fromText("Invalid context store"),
        }}
      />
    )
  }

  const Page = Pages[props.rootId]

  return (
    <>
      <ChainContainer contextStore={props.contextStore} />
      <RootLayout contextStore={props.contextStore}>
        <AutoShowNetworkModal contextStore={props.contextStore} />
        <Suspense
          fallback={
            <LoadingPage
              type="component"
              desc={["Loading"]}
              contextStore={props.contextStore}
            />
          }
        >
          <Page contextStore={props.contextStore} />
        </Suspense>
      </RootLayout>
    </>
  )
}

const PerformRedirect = ({ dest }: { dest: string }): JSX.Element => {
  const navigate = useRouterNavigate()
  useEffect(() => {
    navigate(dest)
  }, [navigate, dest])
  return <LoadingContent messages={[`Redirecting to ${dest}`]} />
}

export type PerpsRoute = { route: "stats" } | PerpsTargetRoute

type PerpsTargetRoute = PerpsNonMarketRoute | PerpsMarketRoute

export type PerpsNonMarketRoute =
  | { route: "markets"; targetSlug: TargetSlug }
  | { route: "earnList"; targetSlug: TargetSlug }
  | { route: "history"; targetSlug: TargetSlug }

export type PerpsMarketRoute =
  | { route: "trade"; targetSlug: TargetSlug; marketSlug: MarketSlug }
  | { route: "earnMarket"; targetSlug: TargetSlug; marketSlug: MarketSlug }
  | { route: "leaderboard"; targetSlug: TargetSlug; marketSlug: MarketSlug }

// TODO:
export type TargetRoute2 =
  | { route: "stats"; targetSlug: TargetSlug }
  | PerpsTargetRoute

export const renderRoute = (dest: PerpsRoute): string => {
  switch (dest.route) {
    case "markets":
      return `/${dest.targetSlug}/${RoutePath.markets}`
    case "trade":
      return `/${dest.targetSlug}/${RoutePath.trade}/${dest.marketSlug}`
    case "earnList":
      return `/${dest.targetSlug}/${RoutePath.earn}`
    case "earnMarket":
      return `/${dest.targetSlug}/${RoutePath.earn}/${dest.marketSlug}`
    case "history":
      return `/${dest.targetSlug}/${RoutePath.history}`
    case "leaderboard":
      return `/${dest.targetSlug}/${RoutePath.leaderboard}/${dest.marketSlug}`
    case "stats":
      return `/${RoutePath.stats}`
  }
}

const redirectRoute = (dest: PerpsRoute) => redirect(renderRoute(dest))

const LoadPerpRoute = (): JSX.Element => {
  return (
    <SetupContext>
      {(contextStore) => (
        <RouteProviders>
          <ShowPage contextStore={contextStore} />
        </RouteProviders>
      )}
    </SetupContext>
  )
}

const RouteProviders = (props: PropsWithChildren) => {
  // For providers which need to be at the highest level within a Route
  return (
    <>
      {props.children}
      <ModalProvider />
    </>
  )
}

const routeJson = (route: PerpsRoute) => json(route)

const routerObjects: RouteObject[] = [
  {
    path: "*",
    element: (
      <SetupContext>
        {(contextStore) => (
          <RouteProviders>
            <ErrorPage contextStore={contextStore} />
          </RouteProviders>
        )}
      </SetupContext>
    ),
  },

  // Valid routes within the application
  {
    path: `/:targetSlug/${RoutePath.markets}`,
    loader: ({ params }) => {
      const targetSlug = params.targetSlug as string
      return routeJson({ route: "markets", targetSlug })
    },
    element: <LoadPerpRoute />,
  },
  {
    path: `/:targetSlug/${RoutePath.trade}/:marketSlug`,
    loader: ({ params }) => {
      const targetSlug = params.targetSlug as string
      const marketSlug = MarketSlug.get(params.marketSlug as string)
      return routeJson({ route: "trade", targetSlug, marketSlug })
    },
    element: <LoadPerpRoute />,
  },
  {
    path: `/:targetSlug/${RoutePath.earn}/:marketSlug?`,
    loader: ({ params }) => {
      const targetSlug = params.targetSlug as string
      const marketSlug = params.marketSlug
      if (marketSlug) {
        return routeJson({
          route: "earnMarket",
          targetSlug,
          marketSlug: MarketSlug.get(marketSlug),
        })
      } else {
        return routeJson({ route: "earnList", targetSlug })
      }
    },
    element: <LoadPerpRoute />,
  },
  {
    path: `/:targetSlug/${RoutePath.history}`,
    loader: ({ params }) => {
      const targetSlug = params.targetSlug as string
      return routeJson({ route: "history", targetSlug })
    },
    element: <LoadPerpRoute />,
  },
  {
    path: `/:targetSlug/${RoutePath.leaderboard}/:marketSlug`,
    loader: ({ params }) => {
      const targetSlug = params.targetSlug as string
      const marketSlug = MarketSlug.get(params.marketSlug as string)
      return routeJson({ route: "leaderboard", targetSlug, marketSlug })
    },
    element: <LoadPerpRoute />,
  },
  {
    path: `/${RoutePath.stats}`,
    loader: () => routeJson({ route: "stats" }),
    element: <LoadPerpRoute />,
  },

  // Fill in missing parameters
  {
    path: "/",
    loader: () => {
      const targetInfo = getDefaultTargetInfo()
      const marketSlug = getDefaultMarketSlug(targetInfo)
      return redirectRoute({
        route: "trade",
        targetSlug: targetInfo.slug,
        marketSlug,
      })
    },
  },
  {
    path: "/:targetSlug",
    loader: ({ params }) => {
      const targetSlug = params.targetSlug as TargetSlug
      const target = domainConfig.getTargetBySlug(targetSlug)

      if (target === undefined) {
        throw new RouteNotFoundError()
      }

      const marketSlug = getDefaultMarketSlug(target)
      return redirectRoute({
        route: "trade",
        targetSlug: target.slug,
        marketSlug,
      })
    },
  },
  {
    path: `/:targetSlug/${RoutePath.trade}`,
    loader: ({ params }) => {
      const targetSlug = params.targetSlug as TargetSlug
      const target = domainConfig.getTargetBySlug(targetSlug)

      if (target === undefined) {
        throw new RouteNotFoundError()
      }

      const marketSlug = getDefaultMarketSlug(target)
      return redirectRoute({
        route: "trade",
        targetSlug: target.slug,
        marketSlug,
      })
    },
  },
  {
    path: `/:targetSlug/${RoutePath.leaderboard}`,
    loader: ({ params }) => {
      const targetSlug = params.targetSlug as TargetSlug
      const target = domainConfig.getTargetBySlug(targetSlug)

      if (target === undefined) {
        throw new RouteNotFoundError()
      }

      const marketSlug = getDefaultMarketSlug(target)
      return redirectRoute({
        route: "leaderboard",
        targetSlug: target.slug,
        marketSlug,
      })
    },
  },

  // Redirect pre target paths
  {
    path: `/${RoutePath.trade}/:marketSlug?`,
    loader: ({ params }) => {
      const target = getDefaultTargetInfo()
      const marketSlug = params.marketSlug
        ? MarketSlug.get(params.marketSlug)
        : getDefaultMarketSlug(target)
      return redirectRoute({
        route: "trade",
        targetSlug: target.slug,
        marketSlug,
      })
    },
  },
  {
    path: `/${RoutePath.earn}/:marketSlug?`,
    loader: ({ params }) => {
      const target = getDefaultTargetInfo()
      const marketSlug = params.marketSlug
      if (marketSlug) {
        return redirectRoute({
          route: "earnMarket",
          targetSlug: target.slug,
          marketSlug: MarketSlug.get(marketSlug),
        })
      } else {
        return redirectRoute({
          route: "earnList",
          targetSlug: target.slug,
        })
      }
    },
  },
  {
    path: `/${RoutePath.history}`,
    loader: () => {
      const target = getDefaultTargetInfo()
      return redirectRoute({ route: "history", targetSlug: target.slug })
    },
  },
  {
    path: `/${RoutePath.leaderboard}/:marketSlug?`,
    loader: ({ params }) => {
      const target = getDefaultTargetInfo()
      const marketSlug = params.marketSlug
        ? MarketSlug.get(params.marketSlug)
        : getDefaultMarketSlug(target)
      return redirectRoute({
        route: "leaderboard",
        targetSlug: target.slug,
        marketSlug,
      })
    },
  },
  {
    path: `/:targetSlug/${RoutePath.stats}/:marketId?`,
    loader: () => redirectRoute({ route: "stats" }),
  },
  {
    path: `/${RoutePath.referral}/:referralCode?`,
    loader: ({ params }) => {
      const referralCode = params.referralCode

      useReferralStore.setState({ externalReferralCode: referralCode })

      if (referralCode) {
        const { defaultTargetInfo } = domainConfig
        const isDomainMainnet = defaultTargetInfo.category === "mainnet"
        const chainConfigKeys = Object.keys(chainConfigMap) as ChainNetworkId[]

        for (const chainNetworkId of chainConfigKeys) {
          const chainNetworkConfig = chainConfigMap[chainNetworkId]
          const isChainMainnet = chainNetworkConfig.network === "mainnet"

          if (referralCode.startsWith(chainNetworkConfig.addressPrefix)) {
            const isChainAddress = isCosmosAddress(
              referralCode,
              chainNetworkConfig.addressPrefix,
            )

            if (!isChainAddress) {
              break
            }

            if (isDomainMainnet === isChainMainnet) {
              const targetId = chainNetworkIdToTargetId(chainNetworkId)
              const target = domainConfig.getTargetById(targetId)

              return redirectRoute({
                route: "trade",
                targetSlug: target.slug,
                marketSlug: getDefaultMarketSlug(target),
              })
            }
          }
        }
      }

      const target = getDefaultTargetInfo()

      return redirectRoute({
        route: "trade",
        targetSlug: target.slug,
        marketSlug: getDefaultMarketSlug(target),
      })
    },
  },
  {
    path: `/:targetSlug/${RoutePath.referral}/:referralCode?`,
    loader: ({ params }) => {
      const targetSlug = params.targetSlug as string
      const targetInfo = domainConfig.getTargetBySlug(targetSlug)
      const marketSlug = getDefaultMarketSlug(targetInfo)

      useReferralStore.setState({ externalReferralCode: params.referralCode })

      return redirectRoute({
        route: "trade",
        targetSlug,
        marketSlug,
      })
    },
  },
]

if (!isProduction) {
  routerObjects.push({
    path: "/test-error-page/:type?",
    loader: ({ params }) => {
      throw new TestErrorPage(params.type || "none-specified")
    },
  })
}

const Router = () => {
  const router = Sentry.wrapCreateBrowserRouter(createBrowserRouter)(
    routerObjects.map((routerObject) => {
      return {
        errorElement: (
          <SetupContext>
            {(contextStore) => (
              <RouteProviders>
                <RouteRootError contextStore={contextStore} />
              </RouteProviders>
            )}
          </SetupContext>
        ),
        ...routerObject,
      }
    }),
  )

  return <RouterProvider router={router} />
}

export default Router
