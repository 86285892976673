import { useTranslation } from "react-i18next"
import { useStore } from "zustand"

import CopyIcon from "@common/icons/CopyIcon"
import DisconnectIcon from "@common/icons/DisconnectIcon"
import { notification } from "@future/notification"
import { track } from "@perps/analytics/track"
import { Event } from "@perps/analytics/events"
import { useChainStore } from "@future/libs/chain/store"

import type { HeaderConnectedListProps } from "."
import type { HeaderMenuListItem } from "../HeaderMenuList"

export const useController = (props: HeaderConnectedListProps) => {
  const { appendList } = props
  const { t } = useTranslation("common")

  const connectedWalletSession = useChainStore((state) =>
    state.connectedWalletSession(),
  )

  const chainPrettyName = useStore(
    props.contextStore,
    (state) => state.chain.config.chainPrettyName,
  )

  const copyAddress = () => {
    if (connectedWalletSession) {
      track(Event.copyWalletAddress())
      navigator.clipboard.writeText(connectedWalletSession.walletAddress)
      notification.success(
        t("wallet.menu.address.notification", { chain: chainPrettyName }),
      )
    }
  }

  const copyProxyAddress = () => {
    if (connectedWalletSession?.proxyWallet) {
      track(Event.copyWalletAddress())
      navigator.clipboard.writeText(connectedWalletSession.proxyWallet.address)
      notification.success(
        t("wallet.menu.proxyAddress.notification", {
          proxy: connectedWalletSession.proxyWallet.name,
        }),
      )
    }
  }

  const disconnect = () => {
    if (connectedWalletSession) {
      track(Event.disconnectWallet())
      connectedWalletSession.disconnect()
    }
  }

  const items: HeaderMenuListItem[] = [
    {
      title: t("wallet.menu.address.title", {
        chain: chainPrettyName,
      }),
      subtitle: connectedWalletSession?.truncatedWalletAddress,
      Icon: CopyIcon,
      action: copyAddress,
    },
  ]

  if (connectedWalletSession?.proxyWallet) {
    items.push({
      title: t("wallet.menu.proxyAddress.title", {
        proxy: connectedWalletSession.proxyWallet.name,
      }),
      subtitle: connectedWalletSession.proxyWallet.truncatedAddress,
      Icon: CopyIcon,
      action: copyProxyAddress,
    })
  }

  if (appendList) {
    items.push(...appendList)
  }

  items.push({
    title: t("wallet.menu.disconnect"),
    Icon: DisconnectIcon,
    action: disconnect,
  })

  return {
    items,
  }
}
