import { useTranslation } from "react-i18next"

import NavigationModal, {
  NavigationModalDynamicItem,
} from "@future/libs/theme/NavigationModal"
import type { ContextStoreProp } from "@future/context/store"

import WalletConnectRoot from "../WalletConnectRoot"

const WalletConnectModal = (props: ContextStoreProp<"standard">) => {
  const { t } = useTranslation("common")

  return (
    <NavigationModal rootId={WalletConnectRoot.name}>
      {(viewProps) => (
        <NavigationModalDynamicItem
          id={WalletConnectRoot.name}
          title={t("wallet.dialog.title")}
          canClose
        >
          <WalletConnectRoot {...viewProps} contextStore={props.contextStore} />
        </NavigationModalDynamicItem>
      )}
    </NavigationModal>
  )
}

export default WalletConnectModal
