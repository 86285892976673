import Box from "@mui/joy/Box"
import CircularProgress from "@mui/joy/CircularProgress"
import { Suspense, lazy } from "react"

import type { MarketId } from "@perps/sdk/types"
import {
  NavigationModalDynamicItem,
  type NavigationModalControlProps,
} from "@future/libs/theme/NavigationModal"

import type {
  BridgeExternalSelection,
  BridgeSelection,
  BridgeInternalSelection,
} from "./BridgeSelector"
import type { ElementsContentProps } from "./elements/ElementsContent"
import { BridgeContentId } from "./constants"

const ElementsContent = lazy(() => import("./elements/ElementsContent"))

export interface BridgeContentProps {
  modal: NavigationModalControlProps
  bridge: BridgeInternalSelection
  marketId: MarketId | undefined
}

/**
 * The first child component must be a `NavigationModalDynamicItem` with the
 * property `id={BridgeContentId}`.
 */
const BridgeContent = (props: BridgeContentProps): JSX.Element => {
  const { bridge } = props

  switch (bridge.type) {
    case "elements":
      return <LazyElementsContent modal={props.modal} values={bridge.values} />
  }
}

const LazyElementsContent = (props: ElementsContentProps) => {
  return (
    <Suspense
      fallback={
        <NavigationModalDynamicItem id={BridgeContentId} canClose>
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
          </Box>
        </NavigationModalDynamicItem>
      }
    >
      <ElementsContent {...props} />
    </Suspense>
  )
}

export const openedBridgeWindow = (
  props: BridgeSelection,
): props is BridgeExternalSelection => {
  if (props.type === "external") {
    window.open(props.url)
    return true
  }
  return false
}

export default BridgeContent
