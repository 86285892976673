import { useStore } from "zustand"

import ChainProvider from "@future/libs/chain/ChainProvider"
import { appDescription, appTitle, appUrl } from "@perps/constants"
import type { ContextStoreProp } from "@future/context/store"

import ChainReporter from "./ChainReporter"

const ChainContainer = (props: ContextStoreProp<"standard">) => {
  const chainConfig = useStore(
    props.contextStore,
    (state) => state.chain.config,
  )

  return (
    <>
      <ChainProvider
        chainConfig={chainConfig}
        walletConnectMetadata={{
          name: appTitle,
          description: appDescription,
          url: appUrl,
          icons: [
            "https://static.levana.finance/icons/levana-silhouette-round.svg",
          ],
        }}
      />
      <ChainReporter />
    </>
  )
}

export default ChainContainer
