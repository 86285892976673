import { QueryCache, QueryClient } from "@tanstack/react-query"

import { AppError } from "@future/libs/error/AppError"
import { notification } from "@future/notification"

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      // Present notification only if theres cached data.
      // Meaning a background update failed.
      if (query.state.data) {
        const appError =
          error instanceof AppError
            ? error
            : AppError.fromError(error, { text: "Unable to perform query" })

        notification.error(appError)
      }
    },
  }),
})
