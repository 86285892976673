import Box from "@mui/joy/Box"
import Sheet from "@mui/joy/Sheet"
import Typography from "@mui/joy/Typography"
import QRCode from "react-qr-code"
import { useTranslation } from "react-i18next"

import type { NavigationModalControlProps } from "@future/libs/theme/NavigationModal"

import WalletConnectAvatar, {
  type WalletConnectAvatarProps,
} from "../WalletConnectAvatar"

export interface WalletConnectQrProps
  extends NavigationModalControlProps,
    WalletConnectAvatarProps {
  qrCode: string
}

const WalletConnectQr = (props: WalletConnectQrProps) => {
  const { qrCode, icon } = props
  const { t } = useTranslation("common")

  return (
    <>
      <Sheet sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <Box sx={{ bgcolor: "#FFF", p: 0.5, lineHeight: 0 }}>
          <QRCode size={256} value={qrCode} />
        </Box>
      </Sheet>

      <Box sx={{ mx: "auto", mt: -2 }}>
        <WalletConnectAvatar icon={icon} />
      </Box>

      <Box sx={{ mt: 2, textAlign: "center" }}>
        <Typography level="body1">{t("wallet.connect.qr.title")}</Typography>
        <Typography level="body2">
          {t("wallet.connect.qr.description")}
        </Typography>
      </Box>
    </>
  )
}

export default WalletConnectQr
