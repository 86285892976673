import type { SeverityLevel as SentrySeverityLevel } from "@sentry/types"
import type { Namespace, ParseKeys, TFunction } from "i18next"
import { AxiosError, type AxiosResponse, CanceledError } from "axios"

import type * as AmpEventData from "@perps/analytics/eventData"
import { isDevelopment } from "@common/env/constants"
import { displayVersion } from "@future/utils/build"

const errorNamespace: Namespace = "error"

export type SeverityLevel = SentrySeverityLevel | "suppress"

export interface ErrorReportingMutableOptions {
  /**
   * Inform logic handling this error if it should be presented.
   */
  disablePresentation?: boolean
  /**
   * Additional metadata relevant to the error.
   */
  extra?: ErrorReportingExtra
  analytics?: AmpEventData.AdditionalErrorOpts
}

export interface ErrorReportingOptions extends ErrorReportingMutableOptions {
  /**
   * An ID which can be mapped to Sentry.
   * Environments which don't send errors to Sentry, such as development, will
   * return an empty string.
   */
  transactionId?: string
  /**
   * The severity level used for logs and Sentry.
   * `suppress` will prevent the error from being sent to Sentry.
   */
  level?: SeverityLevel
  /**
   * Determines which error should be used for the `presentationError`.
   */
  presentable?: boolean
}

interface ErrorReportingExtra {
  link?: { href: string; text: string }
  // biome-ignore lint/suspicious/noExplicitAny: needed to support non-string siblings
  [key: string]: any
}

interface MessageContext<
  Variant extends string,
  Type,
  Extra extends object = object,
> {
  compact: { [_Key in Variant]: Type } & Extra
  reduce: { messageVariant: Variant; message: Type } & Extra
}
type AnyContext = TextContext | KeyContext

type TextMessage = string
type TextContext = MessageContext<"text", TextMessage>

type KeyMessage = ParseKeys<typeof errorNamespace>
interface KeyExtra {
  values?: Record<string, string>
}
type KeyContext = MessageContext<"key", KeyMessage, KeyExtra>

export type AppErrorOptions = ErrorOptions & ErrorReportingOptions
export type AppErrorTextOptions = ErrorReportingOptions & TextContext["compact"]
export type AppErrorKeyOptions = ErrorReportingOptions & KeyContext["compact"]

/**
 * An all purpose error
 *
 * When using the AppError, it's encouraged to add as many layers of context as
 * needed. This is done by creating a new AppError, and passing the existing one
 * into the `cause` property of the new one. By doing this, a context trace
 * starts to form. This can be seen in Example 1.
 *
 * As the context trace is built up, it may not be programatically clear which
 * AppError message should be the one to present. To solve this, the
 * `presentationError` should always be used as the error to display. This
 * property is automatically set as the root of the AppError trace, but it can
 * be changed to a higher-level error with the `presentable` flag. This can be
 * seen in Example 2.
 *
 * In some cases, the original error is needed. This can be accessed from the
 * `rootError` property. If the root error is a value other than AppError, it
 * will exist as the `rootError.cause`. This can be seen in Example 3.
 *
 * @example
 * // Example 1
 * const error = new Error("Failure")
 * const error1 = AppError.fromError(error, {
 *   text: "The original message",
 * })
 * const error2 = AppError.fromText("Message to display", {
 *   cause: error1,
 *   presentable: true,
 * })
 * const error3 = AppError.fromText("Started performing action x", {
 *   cause: error2,
 * })
 *
 * // Example 2
 * error3.presentationError.toString() // "Message to display"
 *
 * // Example 3
 * error3.rootError.cause?.toString() // "Failure"
 */
export class AppError extends Error {
  readonly name = "AppError"
  /**
   * The `context` can be used to access different types of error messages. For
   * instance, to get a typesafe error key message, the following example can be
   * used.
   *
   * @example
   * const { t } = useTranslation("error")
   * const error = AppError.fromKey("wallet.rejected")
   * if (error.context.messageVariant === "key") {
   *   const translatedMessage = t(error.context.message, error.context.values)
   * }
   */
  readonly context: AnyContext["reduce"]
  readonly cause?: unknown
  /**
   * This will always point to the initial AppError.
   */
  readonly rootError: AppError
  /**
   * This will always point to the highest-level AppError that sets the
   * `presentation` property. If none are set, the `rootError` will be used.
   */
  readonly presentationError: AppError
  readonly transactionId: string
  readonly level: SeverityLevel
  disablePresentation: boolean
  extra: ErrorReportingExtra
  analytics: AmpEventData.AdditionalErrorOpts
  #blacklisted = false

  private constructor(args: AnyContext["reduce"] & AppErrorOptions) {
    super(args.message)

    this.cause = args.cause

    this.rootError =
      args.cause instanceof AppError ? args.cause.rootError : this

    this.presentationError = args.presentable
      ? this
      : args.cause instanceof AppError
        ? args.cause.presentationError
        : this

    this.#blacklisted =
      args.cause instanceof AppError
        ? this.#blacklisted
        : isBlackListed(args.cause)

    this.#blacklisted =
      args.cause instanceof AppError
        ? this.#blacklisted
        : isBlackListed(args.cause)

    this.context = ((): AnyContext["reduce"] => {
      switch (args.messageVariant) {
        case "text":
          return {
            messageVariant: args.messageVariant,
            message: args.message,
          }
        case "key":
          return {
            messageVariant: args.messageVariant,
            message: args.message,
            values: args.values,
          }
      }
    })()

    this.transactionId = isDevelopment
      ? ""
      : args.transactionId ??
        Math.random()
          .toString(36)
          .substring(2, 2 + 9)
          .toUpperCase()
          .replaceAll("O", "0")

    this.level = (() => {
      if (this.#blacklisted) {
        return "suppress"
      } else if (args.level) {
        return args.level
      } else if (args.cause instanceof AppError && args.cause.level) {
        return args.cause.level
      } else if (args.cause instanceof CanceledError) {
        return "suppress"
      } else {
        return "error"
      }
    })()

    this.disablePresentation = (() => {
      if (this.#blacklisted) {
        return true
      } else if (args.disablePresentation !== undefined) {
        return args.disablePresentation
      } else if (
        (args.cause instanceof AppError && args.cause.disablePresentation) ||
        args.cause instanceof CanceledError
      ) {
        return true
      } else {
        return false
      }
    })()

    this.extra = (() => {
      const extra = args.extra ?? {}
      extra.version = displayVersion

      if (args.cause instanceof AppError) {
        return { ...args.cause.extra, ...extra }
      } else if (args.cause instanceof AxiosError) {
        if (typeof args.cause.request === "object") {
          if ("responseURL" in args.cause.request) {
            extra.url = `${args.cause.request.responseURL}`
          }
          if ("status" in args.cause.request) {
            extra.status = `${args.cause.request.status}`
          }
        }
        if (args.cause.code) {
          extra.code = args.cause.code
        }

        // another attempt to recover the URL
        if ((!extra.url || extra.url === "") && args.cause.config) {
          extra.url = args.cause.config.url
        }
      }

      return extra
    })()

    this.analytics = (() => {
      const analytics = args.analytics ?? {}

      if (args.cause instanceof AppError) {
        return { ...args.cause.analytics, ...analytics }
      }

      return analytics
    })()
  }

  static fromText(text: TextMessage, options?: AppErrorOptions): AppError {
    return new AppError({
      messageVariant: "text",
      message: text,
      ...options,
    })
  }

  static fromKey(
    key: KeyMessage,
    options?: AppErrorOptions & KeyExtra,
  ): AppError {
    return new AppError({
      messageVariant: "key",
      message: key,
      ...options,
    })
  }

  static fromError(
    error: unknown,
    options: ErrorReportingOptions &
      (TextContext["compact"] | KeyContext["compact"]),
  ): AppError {
    return new AppError({
      ...AppError.compactContextToReduceContext(options),
      ...options,
      cause: error,
    })
  }

  static fromResponse(
    response: AxiosResponse,
    options: ErrorReportingOptions &
      (TextContext["compact"] | KeyContext["compact"]),
  ): AppError {
    return new AppError({
      ...AppError.compactContextToReduceContext(options),
      ...options,
      cause: response.data,
      extra: {
        url: `${response.config.url}`,
        status: `${response.status}`,
        ...(response.statusText && {
          statusText: response.statusText,
        }),
        ...options?.extra,
      },
    })
  }

  static fromQuerierResponse(
    response: AxiosResponse,
    desc: string,
    errorOptions?: { disablePresentation: boolean },
  ): AppError {
    const cause =
      typeof response.data === "object" &&
      "message" in response.data &&
      typeof response.data.message === "string"
        ? response.data.message
        : response.data
    const options = {
      ...errorOptions,
      text: desc,
    }
    return new AppError({
      ...AppError.compactContextToReduceContext(options),
      ...options,
      cause,
      extra: {
        url: `${response.config.url}`,
        status: `${response.status}`,
        ...(response.statusText && {
          statusText: response.statusText,
        }),
      },
    })
  }

  static async withContext<T>(message: string, action: Promise<T>): Promise<T> {
    return AppError.fromAction(action, { text: message })
  }

  static async fromAction<T>(
    action: Promise<T>,
    options: ErrorReportingOptions &
      (TextContext["compact"] | KeyContext["compact"]),
  ): Promise<T> {
    try {
      return await action
    } catch (error) {
      throw AppError.fromError(error, options)
    }
  }

  static composeError(error: unknown): Error | undefined {
    if (error instanceof Error) {
      return error
    } else if (
      error &&
      typeof error === "object" &&
      "message" in error &&
      typeof error.message === "string"
    ) {
      return new Error(error.message)
    } else if (error && typeof error === "string") {
      return new Error(error)
    }
  }

  private static compactContextToReduceContext(
    context: AnyContext["compact"],
  ): AnyContext["reduce"] {
    if ("key" in context) {
      return {
        messageVariant: "key",
        message: context.key,
        values: context.values,
      }
    } else {
      return {
        messageVariant: "text",
        message: context.text,
      }
    }
  }

  private combineContexts(): AnyContext["reduce"][] {
    const data = [this.context]

    if (this.cause instanceof AppError) {
      data.push(...this.cause.combineContexts())
    } else if (this.cause instanceof Error) {
      data.push({
        messageVariant: "text",
        message: this.cause.message,
      })
    }

    return data
  }

  private contextToString(
    context: AnyContext["reduce"],
    t?: TFunction<typeof errorNamespace>,
  ): string {
    switch (context.messageVariant) {
      case "text":
        return context.message
      case "key":
        return t?.(context.message, context.values) ?? context.message
    }
  }

  toContexts(t?: TFunction<typeof errorNamespace>): string[] {
    return this.combineContexts().map((context) => {
      return this.contextToString(context, t)
    })
  }

  toString(t?: TFunction<typeof errorNamespace>): string {
    return this.contextToString(this.context, t)
  }

  toJSON() {
    const handleToJSON = (object: AppError, isCause: boolean) => {
      const propNames = Object.getOwnPropertyNames(object)
      const json: Record<string, unknown> = {}

      for (const name of propNames) {
        switch (name) {
          case "rootError":
          case "presentationError":
            // Don't include cyclical values
            break
          case "stack":
          case "#blacklisted":
            break
          case "cause":
            {
              const cause = object[name as keyof AppError]

              if (cause instanceof AppError) {
                json[name] = handleToJSON(cause, true)
              } else {
                json[name] = cause
              }
            }
            break
          case "analytics":
          case "disablePresentation":
          case "extra":
          case "level":
          case "transactionId":
            // These properties use the AppError cause properties and are redundant
            if (!isCause) {
              json[name] = object[name as keyof AppError]
            }
            break
          default:
            json[name] = object[name as keyof AppError]
        }
      }

      return json
    }

    return handleToJSON(this, false)
  }
}

const blackListClasses: (new () => unknown)[] = [CanceledError]

const blackListRegexes: RegExp[] = [/timeout of \d+\.*\d*ms exceeded/]

const isBlackListed = (error: unknown) => {
  for (const BlackListClass of blackListClasses) {
    if (error instanceof BlackListClass) {
      return true
    }
  }

  if (error instanceof Error) {
    for (const regex of blackListRegexes) {
      if (regex.test(error.message)) {
        return true
      }
    }
  }

  return false
}
