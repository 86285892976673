import ListItemButton, {
  type ListItemButtonProps,
} from "@mui/joy/ListItemButton"
import { forwardRef, useCallback, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { useStore } from "zustand"

import { useNavigate } from "@future/router/useNavigate"
import { useModal } from "@future/libs/modal"
import type { HeaderMenuListItem } from "@perps/header/HeaderMenuList"
import { track } from "@perps/analytics/track"
import { Event } from "@perps/analytics/events"
import ChooseNetworkModal from "@perps/network/ChooseNetworkModal"
import NetworkIcon from "@common/icons/NetworkIcon"
import BridgeIcon from "@common/icons/BridgeIcon"
import LeaderboardIcon from "@common/icons/LeaderboardIcon"
import ValidateIcon from "@common/icons/ValidateIcon"
import SettingsIcon from "@common/icons/SettingsIcon"
import LearnIcon from "@common/icons/LearnIcon"
import BridgeSelector from "@future/bridge/BridgeSelector"
import { Report } from "@future/libs/error/report"
import CrankIcon from "@common/icons/CrankIcon"
import BridgeModal from "@future/bridge/BridgeModal"
import { openedBridgeWindow } from "@future/bridge/BridgeContent"
import { useCrankCallback } from "@future/crank/hooks"
import GetLvnModal from "@future/lvn/GetLvnModal"
import type { MarketConfig } from "@future/market/config/types"
import { lazyLoad } from "@future/utils/lazyLoad"
import { useChainStore } from "@future/libs/chain/store"
import {
  isStandardContextState,
  isStandardContextStore,
  type ContextStore,
  type ContextStoreProp,
} from "@future/context/store"
import ReferralIcon from "@common/icons/ReferralIcon"
import ReferralModal from "@future/referral/ReferralModal"

import GetLvnIcon from "./GetLvnIcon"

export interface UseSecondaryNavListProps extends ContextStoreProp<"initial"> {
  withConnectButton: boolean
}

export const useSecondaryNavList = (props: UseSecondaryNavListProps) => {
  const { contextStore } = props
  const { t } = useTranslation("common")
  const navigate = useNavigate()
  const { present } = useModal()
  const doCrank = useCrankCallback()

  const connectedWalletSession = useChainStore((state) =>
    state.connectedWalletSession(),
  )

  const currentMarketConfig = useStore(contextStore, (state) => {
    if (isStandardContextState(state) && state.isMarketRoute()) {
      return state.fullMarketInfo.config
    }
  })

  const recentMarketSlug = useStore(contextStore, (state) => {
    if (isStandardContextState(state)) {
      return state.fullMarketInfo.config.slug
    }
  })

  const chainNetworkId = useStore(contextStore, (state) => {
    if (isStandardContextState(state)) {
      return state.chain.config.chainNetworkId
    }
  })

  const chainName = useStore(contextStore, (state) => {
    if (isStandardContextState(state)) {
      return state.chain.config.chainName
    }
  })

  const targetSlug = useStore(contextStore, (state) => state.targetInfo.slug)

  const items: HeaderMenuListItem[] = []

  items.push({
    title: t("wallet.menu.network.title"),
    Icon: NetworkIcon,
    actionComponent: NetworkAction(contextStore),
  })

  if (isStandardContextStore(contextStore)) {
    items.push({
      title: t("menu.general.lvn"),
      Icon: GetLvnIcon,
      actionComponent: GetLvnAction(contextStore),
    })
  }

  if (
    isStandardContextStore(contextStore) &&
    (chainNetworkId === "osmosis-mainnet" ||
      chainNetworkId === "injective-mainnet")
  ) {
    items.push({
      title: t("menu.general.bridge"),
      Icon: BridgeIcon,
      actionComponent: BridgeAction(contextStore, currentMarketConfig),
    })
  }

  items.push({
    title: t("menu.general.referral"),
    Icon: ReferralIcon,
    action: () => {
      present(ReferralModal.name)
    },
  })

  if (recentMarketSlug) {
    items.push({
      title: t("menu.general.leaderboard"),
      Icon: LeaderboardIcon,
      action: () => {
        navigate({
          route: "leaderboard",
          targetSlug,
          marketSlug: recentMarketSlug,
        })
      },
    })
  }

  items.push({
    title: t("menu.general.learn"),
    Icon: LearnIcon,
    action: () => {
      window.open("https://levanaexchange.zendesk.com/")
    },
  })

  if (chainName === "osmosis") {
    items.push({
      title: t("menu.general.validate"),
      Icon: ValidateIcon,
      action: () => {
        const url =
          "https://wallet.keplr.app/chains/osmosis?modal=validator&chain=osmosis-1&validator_address=osmovaloper1k5mws9tqgflx6rvhq6x0tnsdw0ddfkptwf3ch9&referral=true/"
        window.open(url)
      },
    })
  }

  if (
    isStandardContextStore(contextStore) &&
    currentMarketConfig &&
    connectedWalletSession
  ) {
    items.push({
      title: t("menu.general.crank"),
      Icon: CrankIcon,
      action: async () => {
        await doCrank({
          contextStore,
          marketConfig: currentMarketConfig,
          connectedWalletSession,
        })
      },
    })
  }

  if (isStandardContextStore(contextStore)) {
    items.push({
      title: t("menu.general.settings"),
      Icon: SettingsIcon,
      actionComponent: SettingsAction(contextStore),
    })
  }

  return { items }
}

const useShowNetworkModal = () => {
  const { present, dismiss } = useModal()
  return useCallback(
    (contextStore: ContextStore<"initial">) => {
      present(
        <ChooseNetworkModal
          isOpen
          close={dismiss}
          contextStore={contextStore}
        />,
        {
          key: "showNetwork",
        },
      )
    },
    [dismiss, present],
  )
}

export const AutoShowNetworkModal = (props: ContextStoreProp<"initial">) => {
  const propsRef = useRef(props)
  const [searchParams] = useSearchParams()
  const autoShowNetworkModal = useRef(
    searchParams.get("networkSelect") !== null,
  )
  const showNetworkModal = useShowNetworkModal()

  useEffect(() => {
    if (autoShowNetworkModal.current) {
      showNetworkModal(propsRef.current.contextStore)
    }
  }, [showNetworkModal])

  return null
}

const NetworkAction = (contextStore: ContextStore<"initial">) => {
  return forwardRef<HTMLDivElement, ListItemButtonProps>(
    ({ onClick, ...forwardProps }, ref) => {
      const showNetworkModal = useShowNetworkModal()

      return (
        <ListItemButton
          ref={ref}
          {...forwardProps}
          onClick={(event) => {
            onClick?.(event)
            showNetworkModal(contextStore)
          }}
        />
      )
    },
  )
}

const BridgeAction = (
  contextStore: ContextStore<"standard">,
  marketConfig?: MarketConfig,
) =>
  forwardRef<HTMLDivElement, ListItemButtonProps>(
    ({ onClick, ...props }, ref) => {
      const { present } = useModal()

      return (
        <BridgeSelector contextStore={contextStore} marketConfig={marketConfig}>
          {(bridgeProps) => (
            <ListItemButton
              ref={ref}
              {...props}
              onClick={(event): null => {
                onClick?.(event)

                if (!bridgeProps) {
                  const { chainNetworkId } =
                    contextStore.getState().chain.config
                  Report.message(`Missing bridge for '${chainNetworkId}'`)
                  return null
                }

                if (!openedBridgeWindow(bridgeProps)) {
                  present(
                    <BridgeModal
                      bridge={bridgeProps}
                      marketId={marketConfig?.id}
                    />,
                  )
                }

                return null
              }}
            />
          )}
        </BridgeSelector>
      )
    },
  )

const SettingsAction = (contextStore: ContextStore<"standard">) =>
  forwardRef<HTMLDivElement, ListItemButtonProps>(
    ({ onClick, ...props }, ref) => {
      const { present } = useModal()

      return (
        <ListItemButton
          ref={ref}
          {...props}
          onClick={async (event) => {
            onClick?.(event)
            track(Event.viewSettings())

            const SettingsModal = await lazyLoad(
              () => import("@perps/settings/SettingsModal"),
            )

            present(<SettingsModal contextStore={contextStore} />)
          }}
        />
      )
    },
  )

const GetLvnAction = (contextStore: ContextStore<"standard">) => {
  return forwardRef<HTMLDivElement, ListItemButtonProps>(
    ({ onClick, ...props }, ref) => {
      const { present } = useModal()

      return (
        <ListItemButton
          ref={ref}
          {...props}
          onClick={(event) => {
            onClick?.(event)
            present(<GetLvnModal contextStore={contextStore} />)
          }}
        />
      )
    },
  )
}
