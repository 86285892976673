import { useTranslation } from "react-i18next"
import Box from "@mui/joy/Box"
import Button from "@mui/joy/Button"
import IconButton from "@mui/joy/IconButton"
import Stack from "@mui/joy/Stack"
import Typography from "@mui/joy/Typography"
import { type ReactNode, useEffect } from "react"
import { useStore } from "zustand"

import MenuIcon from "@common/icons/MenuIcon"
import ChevronRightRoundedIcon from "@common/icons/ChevronRightRoundedIcon"
import type { RouteItem } from "@common/routes/types"
import WalletConnectButton from "@common/wallet/WalletConnectButton"
import { Event } from "@perps/analytics/events"
import { track } from "@perps/analytics/track"
import { useHeaderFaucet } from "@perps/header/useHeaderFaucet"
import { useRoutes } from "@perps/routes"
import ChainLogo from "@perps/network/ChainLogo"
import { useNavigate } from "@future/router/useNavigate"
import type { MarketDisplayConfig } from "@future/market/config/constants"
import type { AprChart } from "@future/market/earnChart/types"
import NotifiContextWrapper from "@future/notifi/NotifiContextWrapper"
import {
  EarnNavMenuOnboard,
  useEarnNavMenuOnboard,
} from "@future/pages/tradeMarket/onboard"
import { useChainStore } from "@future/libs/chain/store"
import {
  isStandardContextState,
  isStandardContextStore,
  type ContextStoreProp,
} from "@future/context/store"
import { useEarnMarketsQuery } from "@future/network/earnMarkets"

import { useController } from "./controller"
import HeaderConnectedButton from "../HeaderConnectedButton"
import HeaderConnectedMenu from "../HeaderConnectedMenu"
import HeaderGeneralMenu from "../HeaderGeneralMenu"
import HeaderLogo from "../HeaderLogo"
import type { HeaderMenuListItem } from "../HeaderMenuList"
import HeaderNavList from "../HeaderNavList"

export interface HeaderLayoutProps extends ContextStoreProp<"initial"> {
  generalList?: HeaderMenuListItem[]
  withConnectButton: boolean
}

const HeaderLayout = (props: HeaderLayoutProps) => {
  const { generalList } = props
  const controller = useController()
  const { t } = useTranslation("perpsCommon")

  const connectedWalletSession = useChainStore((state) =>
    state.connectedWalletSession(),
  )

  const targetSlug = useStore(
    props.contextStore,
    (state) => state.route.targetSlug,
  )

  const market = useStore(props.contextStore, (state) => {
    if (isStandardContextState(state)) {
      return state.fullMarketInfo.config
    }
  })

  const targetInfo = useStore(props.contextStore, (state) => state.targetInfo)

  const scrollDividerHeight = "1px"
  const height = "2.5rem"
  const verticalPadding = controller.isSmallerThanMediumScreen ? "1rem" : "2rem"

  const routerNavigate = useNavigate()
  const showMenuIcon = !controller.isXsScreen && !controller.isMediumScreen

  const { active, enabled, zIndex } = useEarnNavMenuOnboard()

  useEffect(() => {
    if (controller.isNavInMenu) {
      if (active && enabled) {
        controller.navMenuModal.open()
      } else {
        controller.navMenuModal.close()
      }
    }
  }, [
    active,
    enabled,
    controller.isNavInMenu,
    controller.navMenuModal.open,
    controller.navMenuModal.close,
  ])

  return (
    <EarnNavMenuOnboard>
      <Box
        sx={{
          backgroundColor: "var(--palette-background-body)",
          position: "sticky",
          top: "0",
          zIndex: "10",
          mt: scrollDividerHeight,
          px: { xs: 1, sm: 2, md: 3 },
          py: verticalPadding,
        }}
      >
        <Stack
          component="nav"
          direction="row"
          sx={{
            "--Button-radius": "var(--radius-xl)",
            "--IconButton-radius": "var(--Button-radius)",
            height,
            gap: { xs: 1, md: 2 },
            alignItems: "center",
          }}
        >
          <HeaderContent contextStore={props.contextStore} market={market}>
            {({ menuItems, appendedConnectedList, aprPercent }) => (
              <>
                <Stack
                  direction="row"
                  sx={{
                    gap: "inherit",
                    alignItems: "center",
                    flexGrow: 1,
                  }}
                >
                  <HeaderLogo contextStore={props.contextStore} />

                  {menuItems && !controller.isSmallerThanMediumScreen && (
                    <HeaderNavList
                      contextStore={props.contextStore}
                      items={menuItems}
                      orientation="horizontal"
                      size="sm"
                      sx={{
                        "--ListItem-radius":
                          "calc(var(--ListItem-minHeight) / 2)",
                      }}
                    />
                  )}
                </Stack>

                {!controller.isSmallerThanXlScreen && market && aprPercent && (
                  <Button
                    onClick={() => {
                      track(
                        Event.clickAprPromoButton({
                          marketId: market.id,
                          apr: aprPercent,
                        }),
                      )
                      routerNavigate({
                        route: "earnMarket",
                        marketSlug: market.slug,
                        targetSlug,
                      })
                    }}
                    sx={(theme) => ({
                      "--Button-gap": "0",
                      background:
                        "linear-gradient(90deg, #CB91FF -1.61%, #9391FF 100%)",
                      paddingInline: 1,
                      fontSize: theme.vars.fontSize.xs,
                    })}
                    endDecorator={<ChevronRightRoundedIcon />}
                    aria-label="header apr promo"
                  >
                    {t("nav.aprPromo", {
                      apr: aprPercent,
                      baseId: market.base,
                    })}
                  </Button>
                )}
                {connectedWalletSession && !controller.isMediumScreen && (
                  <NotifiContextWrapper
                    walletSession={connectedWalletSession}
                  />
                )}

                {props.withConnectButton &&
                  isStandardContextStore(props.contextStore) && (
                    <>
                      {connectedWalletSession ? (
                        <HeaderConnectedButton
                          ref={controller.connectedMenuModal.ref}
                          isOpen={controller.connectedMenuModal.isOpen}
                          walletSession={connectedWalletSession}
                          onClick={controller.connectedMenuModal.open}
                          aria-label="connected menu"
                        />
                      ) : (
                        <WalletConnectButton />
                      )}

                      <HeaderConnectedMenu
                        anchorEl={controller.connectedMenuModal.ref.current}
                        open={controller.connectedMenuModal.isOpen}
                        onClose={controller.connectedMenuModal.close}
                        placement="bottom-end"
                        appendList={appendedConnectedList}
                        contextStore={props.contextStore}
                      />
                    </>
                  )}

                <IconButton
                  variant="outlined"
                  color="neutral"
                  ref={controller.navMenuModal.ref}
                  onClick={controller.navMenuModal.open}
                  sx={[
                    {
                      gap: 1,
                      paddingInline: "0.5rem",
                    },
                    controller.navMenuModal.isOpen && {
                      backgroundColor: "var(--palette-background-level2)",
                    },
                  ]}
                  aria-label="menu"
                >
                  <ChainLogo
                    size={20}
                    icon={targetInfo.icon}
                    display={targetInfo.name}
                  />
                  {showMenuIcon && <MenuIcon />}
                </IconButton>

                <HeaderGeneralMenu
                  anchorEl={controller.navMenuModal.ref.current}
                  open={controller.navMenuModal.isOpen}
                  onClose={controller.navMenuModal.close}
                  placement="bottom-end"
                  navigationList={menuItems}
                  generalList={generalList}
                  contextStore={props.contextStore}
                  zIndex={zIndex}
                />
              </>
            )}
          </HeaderContent>
        </Stack>
      </Box>
      <Box
        sx={{
          position: "sticky",
          top: `calc(${height} + (${verticalPadding} * 2))`,
          zIndex: "9",
          borderBottom: "1px solid var(--palette-divider)",
          mt: `calc(${scrollDividerHeight} * -1)`,
          mb: 2,
        }}
      />
    </EarnNavMenuOnboard>
  )
}

interface HeaderContentChildrenProps {
  menuItems?: RouteItem[]
  appendedConnectedList?: HeaderMenuListItem[]
  aprPercent?: string | undefined
}

interface HeaderContentProps extends Pick<HeaderLayoutProps, "contextStore"> {
  children: (props: HeaderContentChildrenProps) => React.ReactNode
  market: MarketDisplayConfig | undefined
}

export function getEarnRouteDecorator(content: ReactNode) {
  return (
    <Typography
      sx={{
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontSize: "0.75rem",
        fontWeight: 700,
        backgroundImage:
          "linear-gradient(90deg, #CB91FF -1.61%, #9391FF 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
      }}
    >
      {content}
    </Typography>
  )
}

function getRoutesWithDecorators(
  routeItems: RouteItem[],
  aprContent: string | undefined,
): RouteItem[] {
  return routeItems.map((routeItem) => {
    switch (routeItem.id) {
      case "earn":
        return {
          ...routeItem,
          decorator: aprContent && getEarnRouteDecorator(aprContent),
        }
      default:
        return routeItem
    }
  })
}

const HeaderContent = (props: HeaderContentProps) => {
  if (isStandardContextStore(props.contextStore)) {
    return (
      <HeaderWithStandardContextStore
        {...props}
        contextStore={props.contextStore}
      />
    )
  }

  return <HeaderInner {...props} aprPercent={undefined} />
}

const HeaderWithStandardContextStore = (
  props: Omit<HeaderContentProps, "contextStore"> &
    ContextStoreProp<"standard">,
) => {
  // TODO - might be a nicer way to do this
  // but overall it should probably deal with a variety of cases
  // where data like wallet, apr, etc. is not available

  const earnMarketsResult = useEarnMarketsQuery(props)

  const currentMarketAprs =
    earnMarketsResult.data && props.market
      ? earnMarketsResult.data.get(props.market.id)?.chartMonthly.aprWeekly
      : undefined

  if (currentMarketAprs) {
    return (
      <HeaderWithEarnCharts {...props} currentMarketAprs={currentMarketAprs} />
    )
  }

  return <HeaderInner {...props} aprPercent={undefined} />
}

const HeaderWithEarnCharts = (
  props: Omit<HeaderContentProps, "allEarnMarkets"> & {
    currentMarketAprs: AprChart[]
  },
) => {
  const aprsData = props.currentMarketAprs
  const lastXlpApr =
    aprsData && aprsData.length !== 0
      ? aprsData[aprsData.length - 1].xlp
      : undefined
  const aprPercent = lastXlpApr?.toAsset()

  return <HeaderInner {...props} aprPercent={aprPercent} />
}

const HeaderInner = (
  props: Omit<HeaderContentProps, "allEarnMarkets"> & {
    aprPercent: string | undefined
  },
) => {
  const { aprPercent } = props
  const routes = useRoutes(props)
  const headerFaucet = useHeaderFaucet({
    ...props,
    marketId: props.market?.id,
  })
  const { t } = useTranslation("perpsCommon")

  return props.children({
    menuItems: getRoutesWithDecorators(
      routes.list,
      aprPercent && t("nav.aprEarn", { apr: aprPercent }),
    ),
    appendedConnectedList: headerFaucet.items,
    aprPercent,
  })
}

export default HeaderLayout
