import RadioGroup, {
  type RadioGroupOwnerState,
  type RadioGroupProps,
} from "@mui/joy/RadioGroup"
import { styled } from "@mui/joy/styles"

import { muiMergeSx } from "@common/mui/style"

export interface SegmentedButtonGroupProps extends RadioGroupProps {
  shape?: "default" | "stadium" | "switch"
}

const SegmentedButtonGroup = (props: SegmentedButtonGroupProps) => {
  const { shape, sx, children, ...joyProps } = props
  const inferredShape = shape ?? "default"

  return (
    <StyledSegmentedButtonGroup
      color="neutral"
      {...joyProps}
      orientation="horizontal"
      disableIcon
      sx={[
        ({ vars, typography }) => ({
          ...(inferredShape === "default" && {
            "--Radio-actionRadius": vars.radius.sm,
          }),

          ...(inferredShape === "stadium" && {
            "--variant-borderWidth": "2px",
            "--RadioGroup-gap": "var(--variant-borderWidth)",
            "--Radio-actionRadius": vars.radius.xl,
            gap: "calc(var(--RadioGroup-gap) * 2)",
            padding: "calc(var(--variant-borderWidth) + var(--RadioGroup-gap))",
            borderRadius: "var(--Radio-actionRadius)",
            border: "var(--variant-borderWidth) solid",
            borderColor: vars.palette.background.level3,
          }),

          ...(inferredShape === "switch" && {
            "--variant-borderWidth": "0px",
            "--RadioGroup-gap": "var(--variant-borderWidth)",
            "--Radio-actionRadius": vars.radius.xl,
            "--spacing": "4px",
            borderRadius: "var(--Radio-actionRadius)",
            backgroundColor: vars.palette.primary.softDisabledBg,
            minHeight: 20,
            span: {
              ...typography.caption,
              minWidth: "inherit",
              label: {
                color: vars.palette.primary.softDisabledColor,
              },
              span: {
                "--variant-borderWidth": "0px",
              },
              backgroundColor: vars.palette.primary.softDisabledBg,
              borderRadius: "var(--Radio-actionRadius)",
              "&.Mui-checked": {
                label: {
                  color: vars.palette.primary.softColor,
                },
                backgroundColor: vars.palette.primary.softBg,
              },
            },
          }),
        }),
        (theme) => muiMergeSx(theme, sx),
      ]}
    >
      {children}
    </StyledSegmentedButtonGroup>
  )
}

const StyledSegmentedButtonGroup = styled(RadioGroup)(
  (ownerState: RadioGroupOwnerState) => ({
    ...(ownerState.size === "sm" && {
      minHeight: "2rem",
    }),
    ...(ownerState.size !== "sm" && {
      minHeight: "2.5rem",
    }),
  }),
)

export default SegmentedButtonGroup
