import type { CreateAppErrorKeyOptions } from "@future/libs/error/utils"

import type { WalletRegistry } from "../registry"
import icon from "./icon.png"
import { type KeplrConfig, registry as keplrRegistry } from "../keplr"

export interface XdefiConfig {
  keplr?: KeplrConfig
}

declare global {
  interface Window {
    xfi?: XdefiConfig
  }
}

export type Brand = "xdefi"

const errorOptions: CreateAppErrorKeyOptions = (message) => {
  switch (message) {
    case "XDEFI: user rejected the message signing":
      return {
        key: "wallet.rejected",
        disablePresentation: true,
        level: "suppress",
      }
    default:
      return undefined
  }
}

export const registry: WalletRegistry<XdefiConfig> = {
  name: "XDEFI",
  icon,
  provider: () => window.xfi,
  install: {
    chrome:
      "https://chrome.google.com/webstore/detail/xdefi-wallet/hmeobnfnfcmdkdcmlblgagmfpfboieaf",
  },
  errorOptions,
  accountChangeKey: keplrRegistry.accountChangeKey,
}
