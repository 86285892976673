import type { CreateAppErrorKeyOptions } from "@future/libs/error/utils"

import type { WalletRegistry } from "../registry"
import icon from "./icon.svg"

declare global {
  interface Window {
    leap?: unknown
  }
}

export type Brand = "leap"

const errorOptions: CreateAppErrorKeyOptions = (message) => {
  switch (message) {
    // Extension
    case "Transaction declined":
      return {
        key: "wallet.rejected",
        disablePresentation: true,
        level: "suppress",
      }
    case "No wallet exists":
      return {
        key: "wallet.noAccount",
        presentable: true,
        level: "suppress",
      }
    case "Invalid fee currencies":
      return {
        key: "wallet.invalidCurrencies",
        presentable: true,
        level: "fatal",
      }
    case "Failed to retrieve account from signer":
      return {
        key: "wallet.outOfSyncAccount",
        presentable: true,
        level: "suppress",
      }

    // Mobile
    case "Request rejected by user":
    case "Request has been rejected":
      return {
        key: "wallet.rejected",
        disablePresentation: true,
        level: "suppress",
      }

    default:
      return undefined
  }
}

export const registry: WalletRegistry = {
  name: "Leap",
  icon,
  provider: () => window.leap,
  install: {
    chrome:
      "https://chrome.google.com/webstore/detail/leap-cosmos-wallet/fcfcfllfndlomdhbehjjcoimbgofdncg",
    ios: "https://apps.apple.com/us/app/leap-cosmos/id1642465549",
    android:
      "https://play.google.com/store/apps/details?id=io.leapwallet.cosmos",
  },
  errorOptions,
  accountChangeKey: "leap_keystorechange",
}
