import type { SxProps, SystemStyleObject } from "@mui/system/styleFunctionSx"

export const muiMergeSx = <Theme extends object>(
  theme: Theme,
  sx: SxProps<Theme> | undefined,
): SystemStyleObject<Theme> => {
  if (typeof sx === "function") {
    return sx(theme)
  } else if (Array.isArray(sx)) {
    return sx.reduce((accumulator, current) => {
      return Object.assign(accumulator, muiMergeSx(theme, current))
    }, {})
  } else if (typeof sx === "object") {
    return sx as SystemStyleObject<Theme>
  }
  return {}
}
