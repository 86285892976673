import { queryOptions } from "@tanstack/react-query"

import { SECOND } from "@common/utils/date"
import {
  isStandardContextStore,
  type ContextStore,
} from "@future/context/store"
import { Collateral, NativeToken } from "@future/numerics"
import type { MarketId } from "@perps/sdk/types"

export type WalletBalanceMap = Map<MarketId, WalletBalance>

interface WalletBalance {
  collateral: Collateral
  gas: NativeToken
}

export const walletBalancesQueryOptions = (
  contextStore: ContextStore<"standard">,
  walletAddress: string | undefined,
) => {
  return queryOptions({
    queryKey: ["walletBalances", contextStore, walletAddress],
    refetchInterval: SECOND * 30,
    queryFn: async (context): Promise<WalletBalanceMap> => {
      if (!walletAddress || !isStandardContextStore(contextStore)) {
        return new Map()
      }

      const { chain, markets } = contextStore.getState()

      const coins = await chain.client.queryAllNativeCoinBalance(
        walletAddress,
        context.signal,
      )

      const gasPromise = chain.client
        .queryWalletBalance({
          token: {
            native: {
              denom: chain.config.asset.base,
              decimal_places: 6,
            },
          },
          address: walletAddress,
          coins,
          abortSignal: context.signal,
        })
        .then((gasValue) => {
          return new NativeToken(gasValue)
        })

      const collateralsPromises = [...markets.values()].map(async (market) => {
        const collateralValue = await chain.client.queryWalletBalance({
          token: market.config.collateralToken,
          address: walletAddress,
          coins,
          abortSignal: context.signal,
        })
        const collateral = new Collateral(collateralValue)
        return { marketId: market.config.id, collateral }
      })
      const collateralsPromise = Promise.all(collateralsPromises)

      const [gas, collaterals] = await Promise.all([
        gasPromise,
        collateralsPromise,
      ])

      return collaterals.reduce((acc, { marketId, collateral }) => {
        acc.set(marketId, { gas, collateral })
        return acc
      }, new Map())
    },
  })
}
