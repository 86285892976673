import type { CreateAppErrorKeyOptions } from "@future/libs/error/utils"

import type { WalletRegistry } from "../registry"
import icon from "./icon.png"

declare global {
  interface Window {
    ethereum?: MetaMaskProvider
  }
}

export interface MetaMaskProvider {
  request: (args: MetaMaskRequestArgs) => Promise<string>
}

export type MetaMaskRequestArgs =
  | {
      method: "eth_chainId"
    }
  | {
      method: "wallet_switchEthereumChain"
      params: [{ chainId: "0x1" | "0x5" }]
    }

export type Brand = "metaMask"

const errorOptions: CreateAppErrorKeyOptions = (message) => {
  switch (message) {
    case "User rejected the request":
    case "User denied transaction":
    case "MetaMask Typed Message Signature: User denied message signature.":
      return {
        key: "wallet.rejected",
        disablePresentation: true,
        level: "suppress",
      }
    default:
      return undefined
  }
}

export const registry: WalletRegistry<MetaMaskProvider> = {
  name: "MetaMask",
  icon,
  provider: () => window.ethereum,
  install: {
    chrome:
      "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
    firefox: "https://addons.mozilla.org/en-US/firefox/addon/ether-metamask",
    edge: "https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm",
    ios: "https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202",
    android: "https://play.google.com/store/apps/details?id=io.metamask",
  },
  errorOptions,
  accountChangeKey: undefined,
}
