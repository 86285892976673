import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useSyncExternalStore,
} from "react"

import type { JSONValue } from "@common/json/types"
import { AppError } from "@future/libs/error/AppError"
import { Report } from "@future/libs/error/report"

export const getLocalStorageItem = <Type extends JSONValue>(
  key: string,
): Type | null => {
  if (typeof window === "undefined") {
    return null
  }

  const item = window.localStorage.getItem(key)

  if (!item) {
    return null
  }

  try {
    return JSON.parse(item)
  } catch (error) {
    if (
      typeof item === "string" ||
      typeof item === "number" ||
      typeof item === "boolean"
    ) {
      return item as Type
    }

    Report.error(
      AppError.fromError(error, {
        text: `Failed to parse local storage item with key '${key}'`,
      }),
    )
    return null
  }
}

export const setLocalStorageItem = <Type extends JSONValue>(
  key: string,
  value: Type | null,
) => {
  if (typeof window === "undefined") {
    return
  }

  try {
    const item = JSON.stringify(value)
    window.localStorage.setItem(key, item)
  } catch (error) {
    Report.error(
      AppError.fromError(error, {
        text: `Failed to stringify local storage item with key '${key}'`,
      }),
    )
    return
  }
}

export const removeLocalStorageItem = (key: string) => {
  if (typeof window === "undefined") {
    return
  }

  try {
    window.localStorage.removeItem(key)
  } catch {
    return
  }
}

export const localStorageStateBuilder = <Type extends JSONValue>(
  key: string,
  initial: Type,
) => {
  return {
    key,
    get(): Type {
      return getLocalStorageItem(key) ?? initial
    },
    set(value: Type) {
      setLocalStorageItem(key, value)
    },
  }
}

export const localStorageDynamicStateBuilder = <Type extends JSONValue>(
  key: (itemId: string) => string,
  initial: Type,
) => {
  return {
    key,
    get(itemId: string): Type {
      return getLocalStorageItem(key(itemId)) ?? initial
    },
    set(itemId: string, value: Type) {
      setLocalStorageItem(key(itemId), value)
    },
  }
}

export const useLocalStorage = <Type extends JSONValue>(
  key: string,
  initialValue: Type,
) => {
  const [storedValue, setStoredValue] = useState<Type>(() => {
    try {
      const item = localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.warn("Issue parsing default local storage value", error)
      return initialValue
    }
  })

  const setValue = (value: Type | React.SetStateAction<Type>) => {
    try {
      const finalValue =
        typeof value === "function" ? value(storedValue) : value
      setStoredValue(finalValue)
      localStorage.setItem(key, JSON.stringify(finalValue))
    } catch (error) {
      console.error("Unable to set local storage value", error)
    }
  }

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(storedValue))
  }, [storedValue, key])

  return [storedValue, setValue] as const
}

/**
 * Subscribe to local storage
 *
 * This hooks has a limitation where it only supports primitives.
 */
export const useSubscribeLocalStorage = <
  Type extends string | number | boolean | null,
>(
  key: string,
  initialValue: Type,
) => {
  const initialValueRef = useRef(initialValue)

  const state = useMemo(() => {
    return localStorageStateBuilder(key, initialValueRef.current)
  }, [key])

  const getSnapshot = useCallback(() => {
    return state.get()
  }, [state])

  const subscribe = useCallback((callback: () => void) => {
    window.addEventListener("storage", callback)
    return () => window.removeEventListener("storage", callback)
  }, [])

  const store = useSyncExternalStore(subscribe, getSnapshot)

  const setStore = (value: Type) => {
    state.set(value)
    window.dispatchEvent(
      new StorageEvent("storage", { key, newValue: JSON.stringify(value) }),
    )
  }

  return [store, setStore] as const
}
