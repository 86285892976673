import { create } from "zustand"
import { useEffect, useRef, useState } from "react"

import { AppError } from "@future/libs/error/AppError"
import { Report } from "@future/libs/error/report"

export const lazyLoad = async <Value>(
  lazyImport: () => Promise<{ default: Value }>,
) => {
  try {
    const module = await lazyImport()
    return module.default
  } catch (error) {
    const appError = AppError.fromError(error, {
      text: "Unable to lazy load",
      level: "fatal",
    })
    Report.error(appError)
    throw appError
  }
}

export const useLazyLoad = <Value>(
  lazyImport: () => Promise<{ default: Value }>,
) => {
  const [lazyValue, setLazyValue] = useState<Value | null>(null)
  const lazyImportRef = useRef(lazyImport)

  useEffect(() => {
    const load = async () => {
      try {
        const module = await lazyImportRef.current()
        setLazyValue(() => module.default)
      } catch (error) {
        Report.error(
          AppError.fromError(error, {
            text: "Unable to lazy load",
            level: "fatal",
          }),
        )
      }
    }
    load()
  }, [])

  return lazyValue
}

export const lazyLoadStore = <Data>(loadData: () => Promise<Data>) => {
  interface Props {
    data: Data | undefined
    load: () => Promise<void>
  }

  return create<Props>((set, get) => ({
    data: undefined,
    load: async () => {
      if (!get().data) {
        set({ data: await loadData() })
      }
    },
  }))
}
