import { useEffect, useState } from "react"
import { useStore } from "zustand"
import { useQuery } from "@tanstack/react-query"

import { extractFailureReason } from "@future/libs/error/utils"
import { Event } from "@perps/analytics/events"
import { track } from "@perps/analytics/track"
import { walletBalancesQueryOptions } from "@future/network/walletBalances"
import { useChainStore } from "@future/libs/chain/store"

import type { HeaderFaucetValidateProps } from "."
import { HeaderFaucetNavigation } from "../HeaderFaucetModal/types"

enum FlowState {
  idle,
  tappingFaucet,
  reloadBalance,
}

export const useController = (props: HeaderFaucetValidateProps) => {
  const { onSubmit, onFailure, push } = props

  const targetId = useStore(props.contextStore, (state) => state.targetInfo.id)

  const walletAddress = useChainStore(
    (state) => state.connectedWalletSession()?.walletAddress,
  )

  const walletBalancesResult = useQuery(
    walletBalancesQueryOptions(props.contextStore, walletAddress),
  )

  const [flowState, setFlowState] = useState(FlowState.idle)

  const currentBalance =
    props.marketId && walletBalancesResult.data
      ? walletBalancesResult.data.get(props.marketId)
      : undefined
  const [previousBalance, setPreviousBalance] = useState(currentBalance)

  useEffect(() => {
    if (
      flowState === FlowState.reloadBalance &&
      currentBalance !== previousBalance
    ) {
      setPreviousBalance(currentBalance)
      setFlowState(FlowState.idle)
      track(Event.tapFaucet.succeeded({ chain: targetId }))
      push(HeaderFaucetNavigation.funded)
    }
  }, [flowState, currentBalance, previousBalance, push, targetId])

  const handleSubmit = () => {
    setFlowState(FlowState.tappingFaucet)
    onSubmit()
  }

  const handleSuccess = () => {
    setFlowState(FlowState.reloadBalance)
    walletBalancesResult.refetch()
  }

  const handleFailure = (error: unknown) => {
    setFlowState(FlowState.idle)

    track(
      Event.tapFaucet.failed({ chain: targetId }, extractFailureReason(error)),
    )

    onFailure()
  }

  return {
    isLoading:
      flowState === FlowState.reloadBalance ||
      flowState === FlowState.tappingFaucet,
    handleSubmit,
    handleSuccess,
    handleFailure,
    getCw20s: () => {
      const context = props.contextStore?.getState()

      if (context === undefined) {
        throw new Error("Called getCw20s when target is undefined")
      }

      return [...context.markets.values()].flatMap((market) => {
        if ("cw20" in market.config.collateralToken) {
          return [market.config.collateralToken.cw20.addr]
        } else {
          return []
        }
      })
    },
  }
}
