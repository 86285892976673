import Divider from "@mui/joy/Divider"
import Link from "@mui/joy/Link"
import Stack from "@mui/joy/Stack"
import { useTranslation } from "react-i18next"

import {
  disclaimerUrl,
  privacyPolicyUrl,
  riskDisclosureUrl,
  termsOfServiceUrl,
} from "@perps/legal/constants"

const FooterLegal = () => {
  const { t } = useTranslation("common")

  return (
    <Stack
      direction="row"
      spacing={2}
      divider={<Divider orientation="vertical" />}
      sx={{ flexWrap: "wrap" }}
    >
      <Link
        target="_blank"
        href={termsOfServiceUrl}
        level="body3"
        aria-label="terms of service"
      >
        {t("legal.termsOfService.title")}
      </Link>
      <Link
        target="_blank"
        href={privacyPolicyUrl}
        level="body3"
        aria-label="privacy policy"
      >
        {t("legal.privacyPolicy")}
      </Link>
      <Link
        target="_blank"
        href={disclaimerUrl}
        level="body3"
        aria-label="disclaimer"
      >
        {t("legal.disclaimer")}
      </Link>
      <Link
        target="_blank"
        href={riskDisclosureUrl}
        level="body3"
        aria-label="risk disclosure"
      >
        {t("legal.riskDisclosure")}
      </Link>
      <Link
        target="_blank"
        href="https://status.levana.finance/"
        level="body3"
        aria-label="status page"
      >
        {t("statusPage")}
      </Link>
      <Link
        target="_blank"
        href="https://system-status-channel.levana.finance/"
        level="body3"
        aria-label="status page"
      >
        {t("systemStatusChannel")}
      </Link>
    </Stack>
  )
}

export default FooterLegal
