import List from "@mui/joy/List"
import ListItem from "@mui/joy/ListItem"
import ListItemButton from "@mui/joy/ListItemButton"
import ListItemContent from "@mui/joy/ListItemContent"
import ListItemDecorator from "@mui/joy/ListItemDecorator"
import Stack from "@mui/joy/Stack"
import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"
import Typography from "@mui/joy/Typography"
import CircularProgress from "@mui/joy/CircularProgress"
import { useTranslation } from "react-i18next"
import { useState } from "react"

import InstallIcon from "@common/icons/InstallIcon"
import SegmentedButton from "@common/ui/SegmentedButton"
import SegmentedButtonGroup from "@common/ui/SegmentedButtonGroup"
import {
  WalletType,
  supportsExtension,
  type WalletConnector,
} from "@future/libs/chain/wallet"

import WalletConnectAvatar from "./WalletConnectAvatar"

export enum WalletConnectDevice {
  desktop = "desktop",
  mobile = "mobile",
}

interface WalletConnectListProps {
  connectors: WalletConnector[]
  onConnectorClick: (connector: WalletConnector) => Promise<void>
  device?: WalletConnectDevice
  onDeviceChange?: React.ChangeEventHandler<HTMLInputElement>
  retryConnectorId?: string
  waitingMobileConnectorId?: string
}

const WalletConnectList = (props: WalletConnectListProps) => {
  const { t } = useTranslation("common")

  return (
    <Stack sx={{ gap: 2 }}>
      {!disableMobileList && (
        <SegmentedButtonGroup
          value={props.device}
          onChange={props.onDeviceChange}
          shape="stadium"
        >
          <SegmentedButton
            value={WalletConnectDevice.desktop}
            aria-label="tab desktop"
          >
            <Typography startDecorator={<DesktopIcon />}>
              {t("wallet.dialog.desktop")}
            </Typography>
          </SegmentedButton>
          {process.env.WALLET_CONNECT_ID && (
            <SegmentedButton
              value={WalletConnectDevice.mobile}
              aria-label="tab mobile"
            >
              <Typography startDecorator={<MobileIcon />}>
                {t("wallet.dialog.mobile")}
              </Typography>
            </SegmentedButton>
          )}
        </SegmentedButtonGroup>
      )}

      {props.connectors.length > 0 ? (
        <List
          sx={(theme) => ({
            "--List-gap": theme.spacing(1.5),
            "--ListItem-gap": "auto",
            "--ListItem-minHeight": "3.6rem",
            "--ListItem-radius": theme.radius.sm,
          })}
        >
          {props.connectors.map((connector, index) => (
            <ListItem key={index} variant="outlined">
              <ListItemButton
                onClick={() => props.onConnectorClick(connector)}
                disabled={connector.disabledReason !== undefined}
                sx={{
                  gap: 2,
                  opacity: connector.disabledReason !== undefined ? 0.5 : 1,
                }}
                aria-label={`${connector.brand} wallet`}
              >
                <ListItemDecorator>
                  <WalletConnectAvatar icon={connector.icon} />
                </ListItemDecorator>
                <ListItemContent>
                  <Typography>{connector.name}</Typography>
                  {props.retryConnectorId === connector.brand ? (
                    <Typography level="label2" textColor="text.tertiary">
                      {t("wallet.dialog.refresh")}
                    </Typography>
                  ) : (
                    props.waitingMobileConnectorId === connector.brand && (
                      <Typography level="label2" textColor="text.tertiary">
                        {t("wallet.connect.openMobileApp")}
                      </Typography>
                    )
                  )}
                </ListItemContent>
                {connector.type !== WalletType.input &&
                  connector.installed() === false &&
                  !connector.disabledReason && <InstallIcon color="success" />}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      ) : props.device === WalletConnectDevice.desktop ? (
        <CircularProgress sx={{ alignSelf: "center" }} />
      ) : (
        <Typography
          level="body3"
          sx={({ vars }) => ({
            color: vars.palette.text.primary,
            textAlign: "center",
            my: 4,
          })}
        >
          {t("wallet.dialog.noMobileWallets")}
        </Typography>
      )}
    </Stack>
  )
}

// Temp until mobile connect works
const disableMobileList = true

export const useDevice = (connectors: WalletConnector[]) => {
  const [device, setDevice] = useState(() => {
    if (disableMobileList) {
      return WalletConnectDevice.desktop
    }

    return supportsExtension(connectors)
      ? WalletConnectDevice.desktop
      : WalletConnectDevice.mobile
  })

  const handleDeviceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDevice(event.target.value as WalletConnectDevice)
  }

  return {
    device,
    handleDeviceChange,
  }
}

export const filterConnectors =
  (device: WalletConnectDevice) => (connector: WalletConnector) => {
    switch (device) {
      case WalletConnectDevice.mobile:
        return connector.type === WalletType.mobile && !connector.disabledReason
      case WalletConnectDevice.desktop:
        return (
          (connector.type === WalletType.extension ||
            connector.type === WalletType.input) &&
          !connector.disabledReason
        )
    }
  }

const DesktopIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M4 3C2.89543 3 2 3.89543 2 5V12C2 13.1046 2.89543 14 4 14H8V16H5V17C5 17.5523 5.44772 18 6 18H14C14.5523 18 15 17.5523 15 17V16H12V14H16C17.1046 14 18 13.1046 18 12V5C18 3.89543 17.1046 3 16 3H4ZM12 12H16V5H4V12H8H12Z"
      />
    </SvgIcon>
  )
}

const MobileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path d="M4.09461 6.34385C7.35705 3.21872 12.643 3.21872 15.9097 6.34385L16.3025 6.72086C16.3804 6.7952 16.4243 6.89716 16.4243 7.00362C16.4243 7.11008 16.3804 7.21203 16.3025 7.28637L14.9586 8.57307C14.8754 8.64907 14.7463 8.64907 14.6632 8.57307L14.1221 8.05627C11.8268 5.87495 8.17536 5.87495 5.88002 8.05627L5.3022 8.6112C5.22052 8.68978 5.08955 8.68978 5.00787 8.6112L3.66394 7.3245C3.58574 7.25012 3.54161 7.14794 3.54161 7.04121C3.54161 6.93449 3.58574 6.8323 3.66394 6.75793L4.09461 6.34385ZM18.6874 9.00621L19.8777 10.151C19.9559 10.2254 20 10.3276 20 10.4343C20 10.541 19.9559 10.6432 19.8777 10.7176L14.4922 15.8824C14.3279 16.0392 14.0657 16.0392 13.9014 15.8824L10.0763 12.2171C10.0346 12.1788 9.9697 12.1788 9.92804 12.2171L6.10293 15.8824C5.9386 16.0392 5.67645 16.0392 5.51212 15.8824L0.122331 10.7176C0.0441255 10.6432 0 10.541 0 10.4343C0 10.3276 0.0441255 10.2254 0.122331 10.151L1.31261 9.00621C1.47694 8.84935 1.73909 8.84935 1.90342 9.00621L5.72961 12.6714C5.77112 12.7091 5.83527 12.7091 5.87678 12.6714L9.70297 9.00621C9.86693 8.84948 10.1287 8.84948 10.2927 9.00621L14.1189 12.6714C14.1604 12.7091 14.2246 12.7091 14.2661 12.6714L18.0923 9.00621C18.2571 8.84658 18.5225 8.84658 18.6874 9.00621Z" />
    </SvgIcon>
  )
}

export default WalletConnectList
