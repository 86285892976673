import { type QueryObserverOptions, useQuery } from "@tanstack/react-query"
import { useStore } from "zustand"
import { useCallback } from "react"

import { SECOND } from "@common/utils/date"
import type { ContextStoreProp } from "@future/context/store"
import type { Collateral, NativeToken } from "@future/numerics"
import type { MarketId } from "@perps/sdk/types"
import { useChainStore } from "@future/libs/chain/store"
import { Report } from "@future/libs/error/report"
import type { Amount } from "@future/numerics/amount"
import { useCosmosChainRegistry } from "@future/libs/chain/useCosmosChainRegistry"

export type WalletBalanceMap = Map<MarketId, WalletBalance>

interface WalletBalance {
  collateral: Collateral
  gas: NativeToken
}

export const useWalletNativeCoinBalancesQuery = (
  props: ContextStoreProp<"standard">,
  options?: Pick<QueryObserverOptions, "enabled">,
) => {
  const chainClient = useStore(
    props.contextStore,
    (state) => state.chain.client,
  )
  const chainName = useStore(
    props.contextStore,
    (state) => state.chain.config.chainName,
  )
  const walletAddress = useChainStore(
    (state) => state.connectedWalletSession()?.walletAddress,
  )
  const cosmosChainRegistry = useCosmosChainRegistry()

  return useQuery({
    queryKey: ["walletNativeCoinBalances", walletAddress],
    refetchInterval: SECOND * 30,
    enabled: walletAddress !== undefined && cosmosChainRegistry !== undefined,
    queryFn: async (context) => {
      if (!walletAddress) {
        return {}
      }

      return chainClient.queryAllNativeCoinBalances(
        walletAddress,
        context.signal,
      )
    },
    select: useCallback(
      (data: Record<string, string>) => {
        const balances = new Map<string, Amount>()

        for (const denom in data) {
          const amount = cosmosChainRegistry?.denominatedToNominatedAsset(
            chainName,
            denom,
            data[denom],
          )

          if (!amount) {
            Report.message(
              `Unable to get data from denom (${denom}) on chain (${chainName})`,
              "warning",
            )
            continue
          }

          balances.set(denom, amount)
        }

        return balances
      },
      [cosmosChainRegistry, chainName],
    ),
    ...options,
  })
}
