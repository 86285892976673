import { create } from "zustand"
import { persist } from "zustand/middleware"

import {
  type ConnectedWalletState,
  WalletConnectorStatus,
  type WalletConnector,
  type WalletConnectorsMap,
  type WalletSession,
  type ConnectedWalletSession,
} from "./wallet"

interface UseChainStoreProps {
  walletSession: WalletSession
  connectedWalletSession: () => ConnectedWalletSession | undefined
  walletConnectorsMap: WalletConnectorsMap
  getWalletConnectors: () => WalletConnector[]
}

export const useChainStore = create<UseChainStoreProps>((_set, get) => ({
  walletSession: { status: WalletConnectorStatus.disconnected },
  connectedWalletSession: () => {
    const session = get().walletSession
    return session.status === WalletConnectorStatus.connected
      ? session
      : undefined
  },

  walletConnectorsMap: {},
  getWalletConnectors: () => Object.values(get().walletConnectorsMap),
}))

interface UseChainWalletStateStoreProps {
  walletState: ConnectedWalletState | undefined
}

export const useChainWalletStateStore = create<UseChainWalletStateStoreProps>()(
  persist(
    (_set) => ({
      walletState: undefined,
    }),
    { name: "LevanaChainConnectedWallet" },
  ),
)
