import Box from "@mui/joy/Box"
import IconButton from "@mui/joy/IconButton"
import Stack from "@mui/joy/Stack"
import Typography from "@mui/joy/Typography"
import type { ReactNode } from "react"

import XIcon from "@common/icons/XIcon"

export interface BannerContentDismissal {
  onDismiss?: () => void
}

export interface BannerContentProps extends BannerContentDismissal {
  title: ReactNode
  startDecorator?: JSX.Element
  endDecorator?: JSX.Element
}

const BannerContent = (props: BannerContentProps) => {
  const { title, startDecorator, endDecorator, onDismiss } = props

  return (
    <Stack
      direction="row"
      sx={{
        "--BannerContentIconButton-size": "2rem",
        "--BannerContentIconButton-margin": "1rem",
        "--BannerContent-flexBasisEnd":
          "calc(var(--BannerContentIconButton-size) + var(--BannerContentIconButton-margin))",
        p: 2,
        position: "relative",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          flexBasis: "var(--BannerContent-flexBasisEnd)",
          flexShrink: 999999,
        }}
      />

      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{
          justifyContent: "center",
          alignItems: { xs: "start", sm: "center" },
          columnGap: { xs: 2, md: 4 },
          rowGap: 1,
          flexWrap: "nowrap",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          sx={{
            flexWrap: "nowrap",
            justifyContent: "start",
            alignItems: "center",
            gap: 1,
          }}
        >
          {startDecorator}
          <Typography textColor="text.primary" level="body3">
            {title}
          </Typography>
        </Stack>
        {endDecorator}
      </Stack>

      <Box
        sx={{
          flexBasis: "var(--BannerContent-flexBasisEnd)",
          flexShrink: 999999,
        }}
      >
        {onDismiss && (
          <IconButton
            color="neutral"
            size="sm"
            variant="plain"
            onClick={onDismiss}
            sx={{
              "--IconButton-size": "var(--BannerContentIconButton-size)",
              "--IconButton-radius": "var(--radius-lg)",
              ml: "var(--BannerContentIconButton-margin)",
            }}
            aria-label="close banner"
          >
            <XIcon fontSize="xs" />
          </IconButton>
        )}
      </Box>
    </Stack>
  )
}

export default BannerContent
