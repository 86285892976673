import type { CreateAppErrorKeyOptions } from "@future/libs/error/utils"

import type { WalletRegistry } from "../registry"
import icon from "./icon.png"

declare global {
  interface Window {
    compass?: unknown
  }
}

export type Brand = "compass"

const errorOptions: CreateAppErrorKeyOptions = () => {
  // Compass is forked from Leap. No diverged errors currently.
  return undefined
}

export const registry: WalletRegistry = {
  name: "Compass",
  icon,
  provider: () => window.compass,
  install: {
    chrome:
      "https://chrome.google.com/webstore/detail/compass-wallet-for-sei/anokgmphncpekkhclmingpimjmcooifb",
    ios: "https://apps.apple.com/us/app/compass-wallet-for-sei/id6450257441",
    android:
      "https://play.google.com/store/apps/details?id=io.leapwallet.compass",
  },
  errorOptions,
  accountChangeKey: "compass_keystorechange",
}
