import { useEffect, useRef, useState } from "react"

import {
  useFormController,
  type FormControllerOnSubmit,
} from "@future/libs/form/useFormController"
import { AppError, type AppErrorKeyOptions } from "@future/libs/error/AppError"
import { httpPost } from "@future/utils/http"
import { EventKey } from "@perps/analytics/constants"
import { useChainStore } from "@future/libs/chain/store"

import type { FaucetFormProps } from "."
import type { FaucetFormFields } from "./types"

interface FaucetTapRequest {
  chainId: string
  cw20s: string[]
  faucetAddr: string
  hCaptcha: string
  recipient: string
}

type FaucetResponse =
  | { success: { message: string; txhash: string } }
  | { error: { message: string; error: FaucetTapError } }

type FaucetTapError =
  | { query_eligibility: { inner: string } }
  | { closed_channel: { is_oneshot: boolean; receive: boolean } }
  | { too_many_requests: object }
  | {
      ineligible: {
        seconds: string
        message: string
        reason: "too_soon" | "already_tapped"
      }
    }
  | { contract: { inner: string } }
  | { cannot_query_captcha: object }
  | { invalid_captcha: object }

export const useController = (props: FaucetFormProps) => {
  const { getCw20s, submitFromCaptcha, onSubmit, onSuccess, onFailure } = props

  const connectedWalletSession = useChainStore((state) =>
    state.connectedWalletSession(),
  )

  const defaultValues: FaucetFormFields = {
    address: "",
    captcha: "",
  }

  const handleSubmit: FormControllerOnSubmit<FaucetFormFields> = async (
    values,
  ) => {
    if (onSubmit) {
      onSubmit()
    }

    try {
      const context = props.contextStore.getState()
      const { address, captcha } = values
      const cw20s = getCw20s()

      if (!context.chain?.faucet) {
        throw new Error(
          "Cannot tap faucet, no faucet found. Are you on localnet?",
        )
      }

      const request: FaucetTapRequest = {
        chainId: context.chain.config.chainId,
        cw20s,
        faucetAddr: context.chain.faucet,
        hCaptcha: captcha,
        recipient: address,
      }

      const faucetTapUrl = `https://${context.targetInfo.id}-keeper.sandbox.levana.finance/api/faucet`
      const rawResponse = await httpPost(faucetTapUrl, request, {
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
      })

      if (rawResponse.status !== 200) {
        throw AppError.fromResponse(rawResponse, {
          key: "faucet.unreachable",
        })
      }

      const response: FaucetResponse = rawResponse.data

      if ("success" in response) {
        if (onSuccess) {
          onSuccess()
        }

        return Promise.resolve(null)
      } else if ("error" in response && response.error.message !== undefined) {
        const { key, ...options } = faucetErrorMap(response.error.error)

        throw AppError.fromKey(key, {
          ...options,
          cause: JSON.stringify(response.error),
        })
      } else {
        throw AppError.fromKey("faucet.unknown", {
          cause: JSON.stringify(response),
        })
      }
    } catch (error) {
      formController.fields.captcha.setValue("")

      if (onFailure) {
        onFailure(error)
      }

      return Promise.reject(
        AppError.fromError(error, {
          text: "Faucet form",
          analytics: {
            [EventKey.MARKET]: props.marketId,
          },
        }),
      )
    }
  }

  const formController = useFormController({
    defaultValues,
    onSubmit: handleSubmit,
    walletSession: connectedWalletSession,
  })

  formController.form.register("address", {
    required: true,
  })

  formController.form.register("captcha", {
    required: true,
  })

  const addressValue = formController.fields.address.value
  const setAddressValue = formController.fields.address.setValue
  const [canInsertAddress, setCanInsertAddress] = useState(true)
  const walletAddress = connectedWalletSession?.walletAddress

  useEffect(() => {
    if (walletAddress && canInsertAddress) {
      setCanInsertAddress(false)

      if (addressValue.length === 0) {
        setAddressValue(walletAddress)
      }
    }
  }, [walletAddress, canInsertAddress, addressValue, setAddressValue])

  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (submitFromCaptcha && formController.form.formState.isValid) {
      formRef.current?.requestSubmit()
    }
  }, [submitFromCaptcha, formController.form.formState.isValid])

  return {
    ...formController,
    formRef,
  }
}

const faucetErrorMap = (error: FaucetTapError): AppErrorKeyOptions => {
  if ("ineligible" in error) {
    return {
      key: "faucet.ineligible",
      level: "suppress",
    }
  }
  if ("invalid_captcha" in error) {
    return {
      key: "faucet.invalidCaptcha",
      level: "suppress",
    }
  }
  return {
    key: "faucet.unknown",
    level: "info",
  }
}
