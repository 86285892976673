import type { WalletRegistry } from "../registry"
import { registry as metaMaskRegistry } from "../metaMask"
import icon from "./icon.svg"

export type Brand = "leapMetaMask"

export const registry: WalletRegistry = {
  ...metaMaskRegistry,
  name: "MetaMask",
  icon,
}
