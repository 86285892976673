import Box from "@mui/joy/Box"
import IconButton from "@mui/joy/IconButton"
import ModalClose from "@mui/joy/ModalClose"
import type { PropsWithChildren } from "react"

import ArrowBackIcon from "@common/icons/ArrowBackIcon"

export interface ModalHeaderProps extends PropsWithChildren {
  onBack?: () => void
  canClose?: boolean
}

const ModalHeader = (props: ModalHeaderProps) => {
  const { onBack, canClose, children } = props

  return (
    <Box
      sx={{
        "--IconButton-size": "40px",
        "--IconButton-radius": "calc(var(--IconButton-size) / 2)",
        "--ModalClose-radius": "var(--IconButton-radius)",
        textAlign: "center",
        pt: "var(--ModalContent-padding)",
        px: "var(--ModalContent-padding)",
      }}
    >
      {onBack && (
        <IconButton
          onClick={onBack}
          color="neutral"
          variant="outlined"
          sx={{
            position: "absolute",
            top: "var(--ModalClose-inset)",
            left: "var(--ModalClose-inset)",
          }}
          aria-label="back"
        >
          <ArrowBackIcon />
        </IconButton>
      )}
      {children}
      {canClose && (
        <ModalClose color="neutral" variant="outlined" aria-label="close" />
      )}
    </Box>
  )
}

export default ModalHeader
