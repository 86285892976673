import type { Asset } from "@chain-registry/types"
import type ChainRegistry from "chain-registry"

import { Report } from "@future/libs/error/report"
import { Amount, type ConstructableAmount } from "@future/numerics/amount"
import { lazyLoadStore } from "@future/utils/lazyLoad"

const useCosmosChainRegistryStore = lazyLoadStore(async () => {
  return new CosmosChainRegistry(await import("chain-registry"))
})

/**
 * Cosmos chain registry
 *
 * Access the registry without importing the large file on init.
 */
export const useCosmosChainRegistry = () => {
  const cosmosChainRegistryStore = useCosmosChainRegistryStore()
  cosmosChainRegistryStore.load()
  return cosmosChainRegistryStore.data
}

class CosmosChainRegistry {
  private registry: typeof ChainRegistry

  constructor(registry: typeof ChainRegistry) {
    this.registry = registry
  }

  assets(chainName: string) {
    return this.registry.assets.find(
      (assetGroup) => assetGroup.chain_name === chainName,
    )?.assets
  }

  asset(chainName: string, denom: string) {
    const assets = this.assets(chainName)

    if (assets) {
      for (const asset of assets) {
        if (asset.base === denom) {
          return asset
        }
      }
    }
  }

  assetDecimals(asset: Asset) {
    for (const unit of asset.denom_units) {
      if (unit.denom === asset.display) {
        return unit.exponent
      }
    }

    Report.message(
      `Unable to get decimals for asset with display (${asset.display})`,
      "warning",
    )

    const defaultCosmosDecimals = 6
    return defaultCosmosDecimals
  }

  denominatedToNominatedAsset(
    chainName: string,
    denom: string,
    amount: ConstructableAmount,
  ) {
    const asset = this.asset(chainName, denom)

    if (asset) {
      return Amount.denominatedToNominated(amount, this.assetDecimals(asset))
    }
  }
}
