import {
  type NotifiInputFieldsText,
  type NotifiInputSeparators,
  NotifiSubscriptionCard,
} from "@notifi-network/notifi-react-card"
import { ErrorBoundary } from "@sentry/react"
import { useTranslation } from "react-i18next"

import type { ConnectedWalletSession } from "@future/libs/chain/wallet"
import { useThemeMode } from "@future/libs/theme/useThemeMode"
import { Report } from "@future/libs/error/report"
import { AppError } from "@future/libs/error/AppError"

// import '@notifi-network/notifi-react-card/dist/index.css'
import "./notifi.css"

export interface NotifiCardProps {
  walletSession: ConnectedWalletSession
}

const NotifiCard = ({ walletSession }: NotifiCardProps) => {
  const mode = useThemeMode()
  const { t } = useTranslation("perpsCommon")

  if (walletSession.chainSigningClient.kind === "viewing") {
    // account is required
    return null
  }

  const cardId = ((): string => {
    switch (walletSession.chainSigningClient.kind) {
      case "cosmjs":
        return "362f11d13aaa450ab46931b0fd736d7b"
      case "injective":
        return "061be5d273ae49209b0c925d738e5131"
    }
  })()

  const inputLabels: NotifiInputFieldsText = {
    label: {},
    placeholderText: {
      email: t("notifi.fields.email"),
      telegram: t("notifi.fields.telegram"),
    },
  }

  const inputSeparators: NotifiInputSeparators = {
    emailSeparator: {
      content: t("notifi.separator"),
    },
    telegramSeparator: {
      content: t("notifi.separator"),
    },
  }

  return (
    <ErrorBoundary
      onError={(error) =>
        Report.error(
          AppError.fromError(error, {
            text: "Presenting Notifi",
            level: "fatal",
          }),
        )
      }
    >
      <NotifiSubscriptionCard
        cardId={cardId}
        inputLabels={inputLabels}
        inputSeparators={inputSeparators}
        inputs={{ userWallet: walletSession.walletAddress }}
        copy={{
          FetchedStateCard: {
            SubscriptionCardV1: {
              EditCard: {
                AlertListPreview: {
                  description: t("notifi.alertList.description"),
                },
              },
            },
          },
        }}
        darkMode={mode === "dark"}
      />
    </ErrorBoundary>
  )
}

export default NotifiCard
