import { ErrorBoundary } from "@sentry/react"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import ThemeProvider from "@common/theme/ThemeProvider"
import RootSetup from "@perps/root/RootSetup"
import ConsoleDrawer from "@future/libs/debug/ConsoleDrawer"
import { queryClient } from "@future/network/client"
import NotificationLayout from "@future/notification/NotificationLayout"

import Router from "../router/Router"
import { BrowserConsole } from "../libs/debug/BrowserConsole"

const RootLayout = () => {
  return (
    <ErrorBoundary>
      {/* TODO: the top level error boundary should only be reached if the app cant load */}
      <RootSetup />

      <ThemeProvider>
        <NotificationLayout />
        <QueryClientProvider client={queryClient}>
          <BrowserConsole />
          <ConsoleDrawer />
          <Router />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default RootLayout
