import Box from "@mui/joy/Box"
import Modal from "@mui/joy/Modal"
import ModalDialog from "@mui/joy/ModalDialog"
import { useTheme } from "@mui/joy/styles"
import type { SvgIconProps } from "@mui/joy/SvgIcon"
import Typography from "@mui/joy/Typography"
import Stack from "@mui/joy/Stack"
import useMediaQuery from "@mui/system/useMediaQuery"
import type { SxProps } from "@mui/joy/styles/types"
import {
  useState,
  Fragment,
  useCallback,
  useRef,
  useEffect,
  createContext,
  type PropsWithChildren,
  forwardRef,
} from "react"

import ModalHeader from "@common/ui/ModalHeader"
import ModalTitle from "@common/ui/ModalTitle"
import { useContextOrFail } from "@common/context"
import { muiMergeSx } from "@common/mui/style"
import HelperButton, {
  type HelperButtonAction,
} from "@future/libs/theme/HelperButton"

import { useModal, useModalTopKey } from "../modal"

type NavigationModalItemId = string

export interface NavigationModalItem {
  id: NavigationModalItemId
  title?: React.ReactNode
  subtitle?: React.ReactNode
  icon?: React.JSXElementConstructor<SvgIconProps> | React.ReactElement
  /**
   * When `true`, a back button will be visible in the navigation bar.
   */
  canPop?: boolean
  /**
   * When `true`, a close button will be visible in the navigation bar and
   * tapping outside the modal will perform a close action.
   */
  canClose?: boolean
  /**
   * When set, a help button will appear next to the title.
   */
  helper?: HelperButtonAction
}

export interface NavigationModalStaticItem extends NavigationModalItem {
  view: (
    props: NavigationModalControlProps,
  ) => React.ReactElement<NavigationModalControlProps>
}

export interface NavigationModalControlProps {
  push: (next: NavigationModalItemId | NavigationModalStaticItem) => void
  pop: () => void
  close: () => void
}

type NavigationModalStackItem =
  | ({ type: "dynamic" } & Pick<NavigationModalItem, "id">)
  | ({ type: "static" } & NavigationModalStaticItem)

type NavigationModalDynamicItemMap = Record<
  NavigationModalItemId,
  NavigationModalItem
>

export interface NavigationModalProps {
  rootId: string
  // TODO: replace with slots
  sx?: SxProps
  onDismiss?: () => void
  children: (
    props: NavigationModalControlProps,
  ) => React.ReactElement<NavigationModalItem>
}

/**
 * Modal with a navigation stack
 *
 * There are two types of navigation items which can be pushed onto the stack.
 *
 * 1. A dynamic item is able to update its properties, such as `canClose`, while
 * the view rerenders. This item must exist upfront in the modals children.
 * Pushing these items can be done using their `id`.
 *
 * 2. A static item is unable to update its properties while the view rerenders
 * since it's pushed on the stack with no reference to the original view.
 * Pushing these items must contain all of the static item properties.
 *
 * @example
 * // Dynamic Item
 * <NavigationModal rootId={Settings.name}>
 *   {(viewProps) => (
 *     <NavigationModalDynamicItem id={Settings.name} title="Settings">
 *       <Settings {...viewProps} />
 *     </NavigationModalDynamicItem>
 *   )}
 * </NavigationModal>
 *
 * // Static Item
 * viewProps.push({
 *   id: SettingsUser.name,
 *   canPop: true,
 *   view: (viewProps) => (
 *     <SettingsUser {...viewProps} />
 *   ),
 * })
 */
const NavigationModal = (props: NavigationModalProps) => {
  const { dismiss } = useModal()
  const topModalKey = useModalTopKey()
  const initialTopModalKey = useRef(topModalKey)
  const [dynamicItemMap, setDynamicItemMap] =
    useState<NavigationModalDynamicItemMap>({})
  const [stack, setStack] = useState<NavigationModalStackItem[]>([
    {
      type: "dynamic",
      id: props.rootId,
    },
  ])

  const topItem = ((): NavigationModalItem | undefined => {
    const topStackItem = stack.at(stack.length - 1)

    if (!topStackItem) {
      return undefined
    }

    switch (topStackItem.type) {
      case "dynamic":
        return dynamicItemMap[topStackItem.id]
      case "static":
        return topStackItem
    }
  })()

  const onDismiss = () => {
    dismiss()
    props.onDismiss?.()
  }

  const onDismissRef = useRef(onDismiss)
  useEffect(() => {
    onDismissRef.current = onDismiss
  }, [onDismiss])

  useEffect(() => {
    if (topItem?.canClose) {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          onDismissRef.current()
        }
      }

      document.addEventListener("keydown", handleKeyDown)

      return () => {
        document.removeEventListener("keydown", handleKeyDown)
      }
    }
  }, [topItem?.canClose])

  return (
    <NavigationModalContext.Provider
      value={{
        id: topItem?.id ?? props.rootId,
        setDynamicItemMap,
      }}
    >
      <Modal
        open
        onClose={topItem?.canClose ? () => onDismiss() : undefined}
        sx={[
          (theme) => muiMergeSx(theme, props.sx),
          topModalKey !== initialTopModalKey.current && {
            display: "none",
          },
        ]}
      >
        <NavigationModalDialog
          {...props}
          stack={stack}
          setStack={setStack}
          topItem={topItem}
        />
      </Modal>
    </NavigationModalContext.Provider>
  )
}

interface NavigationModalDialogProps extends NavigationModalProps {
  topItem: NavigationModalItem | undefined
  stack: NavigationModalStackItem[]
  setStack: React.Dispatch<React.SetStateAction<NavigationModalStackItem[]>>
}

// TODO: need to implement with slots for theme compatibility
const NavigationModalDialog = forwardRef<
  HTMLDivElement,
  NavigationModalDialogProps
>(function NavigationModalDialog(props, _ref) {
  const { stack, setStack, topItem } = props
  const { dismiss } = useModal()

  const onPop = useCallback(() => {
    setStack((stack) => (stack.length > 1 ? stack.slice(0, -1) : stack))
  }, [setStack])

  const onNavigationBarPop =
    topItem?.canPop && stack.length > 1 ? onPop : undefined

  const onPush: NavigationModalControlProps["push"] = useCallback(
    (next) => {
      setStack((stack) => {
        if (typeof next === "string") {
          return [...stack, { type: "dynamic", id: next }]
        } else {
          return [...stack, { type: "static", ...next }]
        }
      })
    },
    [setStack],
  )

  const onDismiss = () => {
    dismiss()
    props.onDismiss?.()
  }

  // TODO: this should be handled by Joy and the modal should have another layout option for 'auto'
  const theme = useTheme()
  const centerBreakpoint = "sm"
  const isCenterBreakpoint = useMediaQuery(
    theme.breakpoints.up(centerBreakpoint),
  )

  return (
    <ModalDialog
      size="lg"
      layout={isCenterBreakpoint ? "center" : "fullscreen"}
    >
      <ModalHeader onBack={onNavigationBarPop} canClose={topItem?.canClose}>
        {topItem?.subtitle && (
          <Typography
            level="button2"
            sx={{
              height:
                "var(--ModalHeader-height, calc(var(--IconButton-size) * 0.66))",
            }}
          >
            {topItem.subtitle}
          </Typography>
        )}
        <ModalTitle>
          {topItem?.icon && (
            <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
              {typeof topItem.icon === "function" ? (
                <topItem.icon sx={{ color: "text.secondary" }} />
              ) : (
                <>{topItem.icon}</>
              )}
            </Box>
          )}
          {topItem?.helper ? (
            <Stack
              direction="row"
              columnGap={1}
              alignItems="center"
              justifyContent="center"
            >
              {topItem.title}
              <HelperButton
                {...topItem.helper}
                aria-label={`modal ${topItem.title} helper`}
              />
            </Stack>
          ) : (
            topItem?.title
          )}
        </ModalTitle>
      </ModalHeader>
      <Box
        sx={{
          width: "100%",
          alignSelf: "center",
          overflow: "auto",
          px: {
            xs: `max(
              var(--ModalContent-padding), 
              calc((100vw - var(--ModalContent-maxWidth)) / 2)
            )`,
            [centerBreakpoint]:
              "calc((var(--ModalDialog-maxWidth) - var(--ModalContent-maxWidth)) / 2)",
          },
          pb: "var(--ModalContent-padding)",
        }}
      >
        <Box sx={{ mt: "var(--NavigationModal-contentTop, 0.5rem)" }}>
          {stack.map((item) => (
            <Fragment key={item.id}>
              {item.type === "static" && (
                <PreventUnmounting show={topItem?.id === item.id}>
                  <item.view push={onPush} pop={onPop} close={onDismiss} />
                </PreventUnmounting>
              )}
            </Fragment>
          ))}

          {props.children({ push: onPush, pop: onPop, close: onDismiss })}
        </Box>
      </Box>
    </ModalDialog>
  )
})

export const NavigationModalDynamicItem = (
  props: PropsWithChildren<NavigationModalItem>,
) => {
  const { id, setDynamicItemMap } = useContextOrFail(NavigationModalContext)
  const { canClose, canPop, subtitle, title } = props
  const propsRef = useRef(props)
  useEffect(() => {
    propsRef.current = props
  }, [props])

  useEffect(() => {
    setDynamicItemMap((map) => ({
      ...map,
      [propsRef.current.id]: {
        id: propsRef.current.id,
        canClose,
        canPop,
        icon: propsRef.current.icon,
        subtitle,
        title,
        helper: propsRef.current.helper,
      },
    }))
  }, [setDynamicItemMap, canClose, canPop, subtitle, title])

  return (
    <PreventUnmounting show={id === props.id}>
      {props.children}
    </PreventUnmounting>
  )
}

const PreventUnmounting = (props: PropsWithChildren<{ show: boolean }>) => {
  return (
    <Box sx={{ display: props.show ? "block" : "none" }}>{props.children}</Box>
  )
}

interface NavigationModalContextProps {
  id: NavigationModalItemId
  setDynamicItemMap: React.Dispatch<
    React.SetStateAction<NavigationModalDynamicItemMap>
  >
}

const NavigationModalContext = createContext<
  NavigationModalContextProps | undefined
>(undefined)

export default NavigationModal
