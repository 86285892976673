import type { CreateAppErrorKeyOptions } from "@future/libs/error/utils"

import type { KeplrConfig } from "../keplr"
import type { WalletRegistry } from "../registry"
import icon from "./icon.png"

export interface OkxConfig {
  keplr?: KeplrConfig
}

declare global {
  interface Window {
    okxwallet?: OkxConfig
  }
}

export type Brand = "okx"

const extensionCosmosRejectMessage =
  "Request Signature: User denied request signature."
const extensionInjectiveRejectMessage =
  "Message Signature: User denied message signature."
const browserRejectMessage = "The user rejected the request"

const errorOptions: CreateAppErrorKeyOptions = (message) => {
  switch (message) {
    case extensionCosmosRejectMessage:
    case extensionInjectiveRejectMessage:
    case browserRejectMessage:
      return {
        key: "wallet.rejected",
        disablePresentation: true,
        level: "suppress",
      }
    // case "Already processing wallet_requestIdentities. Please wait.":
    //   return // ???: what does this mean
    default:
      return undefined
  }
}

export const registry: WalletRegistry<OkxConfig> = {
  name: "OKX",
  icon,
  provider: () => window.okxwallet,
  install: {
    chrome:
      "https://chrome.google.com/webstore/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge",
    ios: "https://apps.apple.com/us/app/okx-buy-bitcoin-btc-crypto/id1327268470",
    android: "https://play.google.com/store/apps/details?id=com.okinc.okex.gp",
  },
  errorOptions,
  accountChangeKey: "okxwallet_keystorechange",
}
