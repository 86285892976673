import Typography, { type TypographyProps } from "@mui/joy/Typography"

const ModalTitle = (props: TypographyProps) => {
  const { sx, children, ...joyProps } = props

  return (
    <Typography
      {...joyProps}
      level="h3"
      sx={{
        ...sx,
        textAlign: "center",
      }}
    >
      {children}
    </Typography>
  )
}

export default ModalTitle
