import type { CreateAppErrorKeyOptions } from "@future/libs/error/utils"

import type { WalletRegistry } from "../registry"
import icon from "./icon.png"

declare global {
  interface Window {
    cosmostation?: unknown
  }
}

export type Brand = "cosmostation"

export const rejectMessage = "User rejected the request."

const errorOptions: CreateAppErrorKeyOptions = (message) => {
  switch (message) {
    case rejectMessage:
      return {
        key: "wallet.rejected",
        disablePresentation: true,
        level: "suppress",
      }
    case "The JSON sent is not a valid Request object.":
      return {
        key: "wallet.noAccount",
        presentable: true,
        level: "suppress",
      }
    default:
      return undefined
  }
}

export const registry: WalletRegistry = {
  name: "Cosmostation",
  icon,
  provider: () => window.cosmostation,
  install: {
    chrome:
      "https://chrome.google.com/webstore/detail/cosmostation/fpkhgmpbidmiogeglndfbkegfdlnajnf",
    ios: "https://apps.apple.com/us/app/cosmostation/id1459830339",
    android:
      "https://play.google.com/store/apps/details?id=wannabit.io.cosmostaion",
  },
  errorOptions,
  accountChangeKey: "cosmostation_keystorechange",
}
