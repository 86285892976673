import { create } from "zustand"
import { persist } from "zustand/middleware"

import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "@common/storage/localStorage"
import type { TargetId } from "@common/target/types"

interface UseRpcEndpointStoreProps {
  endpoints: Record<string, string>
  /**
   * The `chainId` is temporarily used for migrating local storage values
   */
  endpoint: (targetId: TargetId, chainId: string) => string
  setEndpoint: (targetId: TargetId, endpoint: string) => void
}

export const useRpcEndpointStore = create<UseRpcEndpointStoreProps>()(
  persist(
    (set, get) => ({
      endpoints: {},
      endpoint: (targetId, chainId) => {
        const { endpoints } = get()

        if (targetId in endpoints) {
          return endpoints[targetId]
        }

        // Check if theres a value with the older key
        const key = `SettingsRpcEndpointOverride-${chainId}`
        const endpoint = getLocalStorageItem(key)

        if (endpoint && typeof endpoint === "string") {
          get().setEndpoint(targetId, endpoint)
          removeLocalStorageItem(key)
          return endpoint
        }

        return ""
      },
      setEndpoint: (targetId, endpoint) => {
        if (endpoint) {
          const endpoints = { ...get().endpoints }
          endpoints[targetId] = endpoint
          set({ endpoints })
        } else {
          const { [targetId]: _, ...endpoints } = get().endpoints
          set({ endpoints })
        }
      },
    }),
    { name: "RpcEndpoint" },
  ),
)
