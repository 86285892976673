import Radio, { type RadioProps } from "@mui/joy/Radio"
import type { PropsWithChildren } from "react"

export type SegmentedButtonProps = Omit<RadioProps, "label"> & PropsWithChildren

const SegmentedButton = (props: SegmentedButtonProps) => {
  const { sx, children, ...joyProps } = props

  return (
    <Radio
      color="neutral"
      variant="plain"
      {...joyProps}
      label={children}
      sx={{
        ...sx,
        px: 2,
        flexBasis: "100%",
        alignItems: "center",
        textAlign: "center",
      }}
      slotProps={{
        action: ({ checked }) => ({
          sx: {
            "--variant-borderWidth": "2px",

            ...(checked && {
              bgcolor: "background.level3",

              "&:hover, &:active": {
                bgcolor: "background.level3",
              },
            }),

            ...(!checked && {
              "&:hover": {
                bgcolor: "background.level2",
              },

              "&:active": {
                bgcolor: "background.level3",
              },
            }),
          },
        }),
        input: {
          sx: {
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          },
        },
        label: ({ checked, disabled }) => ({
          sx: {
            color: checked
              ? "text.primary"
              : disabled
                ? "text.tertiary"
                : "text.secondary",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }),
      }}
    />
  )
}

export default SegmentedButton
