import Button from "@mui/joy/Button"
import { useTranslation } from "react-i18next"
import { useCallback } from "react"

import { useModal } from "@future/libs/modal"
import {
  type WalletExtensionConnector,
  WalletType,
  isKeplrBrowser,
  isLeapBrowser,
} from "@future/libs/chain/wallet"
import { notification } from "@future/notification"
import type { WalletBrand } from "@future/libs/chain/wallets/registry"
import { useChainStore } from "@future/libs/chain/store"

import WalletConnectModal from "../WalletConnectModal"

const WalletConnectButton = () => {
  const walletConnect = useWalletConnect()
  const { t } = useTranslation("common")

  return (
    <Button
      variant="solid"
      onClick={walletConnect.handleClick}
      aria-label="connect"
    >
      {t("wallet.connect.title")}
    </Button>
  )
}

export const useWalletConnect = () => {
  const { present } = useModal()
  const { t } = useTranslation("common")

  const extensionConnect = useCallback(
    async (brand: WalletBrand) => {
      let extensionConnector: WalletExtensionConnector | undefined

      for (const connector of useChainStore.getState().getWalletConnectors()) {
        if (
          connector.brand === brand &&
          connector.type === WalletType.extension
        ) {
          extensionConnector = connector
          break
        }
      }

      if (extensionConnector) {
        extensionConnector.connect()
      } else {
        notification.warning(t("wallet.unsupported.permanently"))
      }
    },
    [t],
  )

  const handleClick = async () => {
    // Some wallets try to provide a simple transition from Keplr, such as Leap.
    // This means the those wallets might add the Keplr user agent.
    // Make sure Keplr is at the end of the conditions.
    if (isLeapBrowser) {
      extensionConnect("leap")
    } else if (isKeplrBrowser) {
      extensionConnect("keplr")
    } else {
      present(WalletConnectModal.name)
    }
  }

  return {
    handleClick,
  }
}

export default WalletConnectButton
