// Abstraction around all frontend fetching
// Internally uses axios for retry and cancellation
// And promise-throttle for throttling querier requests
import axios, {
  type AxiosRequestConfig,
  type AxiosResponse,
  CanceledError,
} from "axios"
import axiosRetry from "axios-retry"

import { Report } from "@future/libs/error/report"
import { AppError } from "@future/libs/error/AppError"

import { throttleRequest } from "./throttling"

export interface AbortProps {
  abortSignal: AbortSignal
}

const DEFAULT_RETRIES = 3
const DEFAULT_TIMEOUT = 5000

const axiosClient = axios.create()

axiosRetry(axiosClient, {
  retries: DEFAULT_RETRIES,
  retryDelay: axiosRetry.exponentialDelay,
  onRetry: (retryCount, error, requestConfig) => {
    if (error instanceof CanceledError) {
      return
    }

    Report.addBreadcrumb({
      level: "error",
      type: "http",
      category: "network",
      message: "Fetch failed",
      data: {
        retryCount,
        error,
        url: requestConfig.url,
      },
    })

    if (retryCount === DEFAULT_RETRIES) {
      Report.error(
        AppError.fromError(error, {
          text: "AxiosRetry failed",
        }),
      )
    }
  },
})

// biome-ignore lint/suspicious/noExplicitAny: just wrapping the inner axios client
export async function httpGet<T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  config?: AxiosRequestConfig<D>,
): Promise<R> {
  return doHttp(url, () => axiosClient.get(url, patchConfig(config)))
}

// biome-ignore lint/suspicious/noExplicitAny: just wrapping the inner axios client
export async function httpDelete<T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  config?: AxiosRequestConfig<D>,
): Promise<R> {
  return doHttp(url, () => axiosClient.delete(url, patchConfig(config)))
}

// biome-ignore lint/suspicious/noExplicitAny: just wrapping the inner axios client
export async function httpHead<T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  config?: AxiosRequestConfig<D>,
): Promise<R> {
  return doHttp(url, () => axiosClient.head(url, patchConfig(config)))
}

// biome-ignore lint/suspicious/noExplicitAny: just wrapping the inner axios client
export async function httpOptions<T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  config?: AxiosRequestConfig<D>,
): Promise<R> {
  return doHttp(url, () => axiosClient.options(url, patchConfig(config)))
}

// biome-ignore lint/suspicious/noExplicitAny: just wrapping the inner axios client
export async function httpPost<T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
): Promise<R> {
  return doHttp(url, () => axiosClient.post(url, data, patchConfig(config)))
}

// biome-ignore lint/suspicious/noExplicitAny: just wrapping the inner axios client
export async function httpPut<T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
): Promise<R> {
  return doHttp(url, () => axiosClient.put(url, data, patchConfig(config)))
}

// biome-ignore lint/suspicious/noExplicitAny: just wrapping the inner axios client
export async function httpPatch<T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
): Promise<R> {
  return doHttp(url, () => axiosClient.patch(url, data, patchConfig(config)))
}

// biome-ignore lint/suspicious/noExplicitAny: just wrapping the inner axios client
export async function httpPostForm<T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
): Promise<R> {
  return doHttp(url, () => axiosClient.postForm(url, data, patchConfig(config)))
}

// biome-ignore lint/suspicious/noExplicitAny: just wrapping the inner axios client
export async function httpPutForm<T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
): Promise<R> {
  return doHttp(url, () => axiosClient.putForm(url, data, patchConfig(config)))
}

// biome-ignore lint/suspicious/noExplicitAny: just wrapping the inner axios client
export async function patchForm<T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
): Promise<R> {
  return doHttp(url, () =>
    axiosClient.patchForm(url, data, patchConfig(config)),
  )
}

export function isAxiosErrorWithCode(e: unknown, code: number): boolean {
  return axios.isAxiosError(e) && e.status !== undefined && e.status === code
}

// this abstraction makes it easier to debug some kinds of network issues
// biome-ignore lint/suspicious/noExplicitAny: just wrapping the inner axios client
async function doHttp<T = any, R = AxiosResponse<T>>(
  url: string,
  reqFn: () => Promise<R>,
): Promise<R> {
  return throttleRequest(url, reqFn)
}

function patchConfig<D>(config?: AxiosRequestConfig<D>): AxiosRequestConfig<D> {
  return {
    ...config,
    timeout: config?.timeout ?? DEFAULT_TIMEOUT,
    validateStatus: () => true,
  }
}
