import { create } from "zustand"

import { isCosmosAddress } from "@future/libs/chain/wallet"

import { ReferralMap } from "./referralMap"

/**
 * List of referral handles mapped to referral addresses
 *
 * The address can be a wallet or contract address, on any chain
 */
export const referralMap = new ReferralMap({
  cryptoicehouse: ["osmo1xc3fvr4fezxw6w8wnuu63p3sln8nn7s8wrzuqf"],
  Banter: ["osmo16hrqwd98vm9tftf9rpz7nfd3epw7yd35hhkqh8"],
  SkyWee: ["osmo169er57d3feyfss5z3ssj7agjx0r32sdhcqzql6"],
})

interface UseReferralStoreProps {
  externalReferralCode: string | undefined
  validExternalReferralAddress: (addressPrefix: string) => string | undefined
}

export const useReferralStore = create<UseReferralStoreProps>((_set, get) => ({
  externalReferralCode: undefined,
  validExternalReferralAddress: (addressPrefix) => {
    const { externalReferralCode } = get()

    if (!externalReferralCode) {
      return undefined
    }

    if (isCosmosAddress(externalReferralCode, addressPrefix)) {
      return externalReferralCode
    }

    return referralMap.getHandleToAddress(externalReferralCode, addressPrefix)
  },
}))
