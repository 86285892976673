import { useEffect } from "react"

import { ModalPresenter, useModal } from "@future/libs/modal"
import type { ContextStoreProp } from "@future/context/store"

import ReferralModal from "./ReferralModal"
import { useReferralStore } from "./referralCode"

const ReferralModalPresenter = (props: ContextStoreProp<"standard">) => {
  const { present } = useModal()
  const externalReferralCode = useReferralStore(
    (state) => state.externalReferralCode,
  )

  useEffect(() => {
    if (externalReferralCode) {
      present(ReferralModal.name)
    }
  }, [externalReferralCode, present])

  return (
    <ModalPresenter queueKey={ReferralModal.name}>
      <ReferralModal contextStore={props.contextStore} />
    </ModalPresenter>
  )
}

export default ReferralModalPresenter
