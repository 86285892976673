import Button, { type ButtonProps } from "@mui/joy/Button"
import CircularProgress from "@mui/joy/CircularProgress"
import { useDeferredValue } from "react"
import { useFormContext } from "react-hook-form"

import { isPending } from "@future/libs/form/utils"

export type FormSubmitButtonProps = ButtonProps

const FormSubmitButton = (props: FormSubmitButtonProps) => {
  const { formState } = useFormContext()
  const isValid = useDeferredValue(formState.isValid)
  const hasFormErrors = formState.errors.root !== undefined

  return (
    <Button
      variant="solid"
      aria-label="form submit"
      {...props}
      type="submit"
      disabled={!isValid || hasFormErrors || props.disabled}
      loading={isPending(formState)}
      loadingIndicator={
        <CircularProgress
          color={props.color}
          size={props.size}
          sx={{ color: "white" }}
        />
      }
    />
  )
}

export default FormSubmitButton
