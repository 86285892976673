import Box from "@mui/joy/Box"
import Button from "@mui/joy/Button"
import Input from "@mui/joy/Input"
import Stack from "@mui/joy/Stack"
import Typography from "@mui/joy/Typography"
import { useTranslation } from "react-i18next"
import { useState } from "react"

import {
  isCosmosAddress,
  type WalletInputConnector,
} from "@future/libs/chain/wallet"
import type { NavigationModalControlProps } from "@future/libs/theme/NavigationModal"
import InfoIcon from "@common/icons/InfoIcon"
import type { ContextStoreProp } from "@future/context/store"

export interface WalletConnectInputProps
  extends NavigationModalControlProps,
    ContextStoreProp<"standard"> {
  connector: WalletInputConnector
}

const WalletConnectInput = (props: WalletConnectInputProps) => {
  const { t } = useTranslation("common")
  const [address, setAddress] = useState("")
  const [error, setError] = useState(false)

  const onConnect = () => {
    props.connector.connect(address)
  }

  const hasError = (address: string): boolean => {
    if (address.length === 0) {
      return false
    }

    const chainConfig = props.contextStore.getState().chain.config
    return !isCosmosAddress(address, chainConfig.addressPrefix)
  }

  return (
    <Stack spacing={2}>
      <Input
        placeholder={t("wallet.connect.input.placeholder")}
        value={address}
        onChange={(event) => {
          const address = event.target.value
          setAddress(address)
          setError(hasError(address))
        }}
        error={error}
        autoFocus
        aria-label="input connect field"
      />
      <Typography
        level="label2"
        textColor="text.secondary"
        startDecorator={
          <Box
            sx={({ vars }) => ({
              bgcolor: vars.palette.background.level3,
              display: "flex",
              borderRadius: vars.radius.md,
              p: 0.3,
            })}
          >
            <InfoIcon />
          </Box>
        }
      >
        {t("wallet.connect.input.note")}
      </Typography>
      <Button
        onClick={onConnect}
        disabled={error || address.length === 0}
        aria-label="input connect"
      >
        {t("wallet.connect.input.connect")}
      </Button>
    </Stack>
  )
}

export default WalletConnectInput
